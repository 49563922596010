import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
  isLoggedIn: true,
};
const AuthStore = createSlice({
  name: "AuthSlice",
  initialState: initialValue,
  reducers: {
    setIsLoggedIn: (state, actions) => {
      state.isLoggedIn = actions.payload;
    },
  },
});

export default AuthStore.reducer;
export const { setIsLoggedIn } = AuthStore.actions;
