import { configureStore } from "@reduxjs/toolkit";
import PetPartnerListStore from "./findPartner/patnerList";
import PetPartnerListFilters from "./findPartner/appliedFilters";
import authStore from "./authStore";
const PetStore = configureStore({
  reducer: {
    findPartner: PetPartnerListStore,
    findPartnerFilter: PetPartnerListFilters,
    authSlice: authStore,
  },
});

export default PetStore;
