import { faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";

function CustomUploadProfile({
  label,
  labelClass,
  outerDivClass,
  size,
  color,
  uploadIconClass,
  required,
  onChange,
  ...props
}) {
  const uploadPic = useRef();
  const [uploadedImg, setUploadedImg] = useState(null);
  const HandleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUploadedImg(event.target.result);
      };
      reader.readAsDataURL(file);
    }
    onChange(e);
  };
  return (
    <div className={outerDivClass}>
      <div>
        <label className={labelClass}>{label} </label>
        {required && <span className="text-md text-red-600 font-bold">*</span>}
      </div>
      <div>
        {(!uploadedImg && (
          <FontAwesomeIcon
            icon={faUpload}
            size={size || "xl"}
            style={{ cursor: "pointer" }}
            color={color || "#000000"}
            className={uploadIconClass}
            onClick={() => uploadPic.current.click()}
          />
        )) || (
          <div
            style={{ backgroundImage: `url(${uploadedImg})` }}
            className="relative h-[20vh] w-[10vw] border border-gray-400 bg-contain bg-no-repeat bg-center"
          >
            <div
              className="h-6 w-6 bg-red-600 absolute right-0 top-0 cursor-pointer rounded-xl text-center"
              onClick={() => setUploadedImg(null)}
            >
              <FontAwesomeIcon icon={faXmark} className="text-light" />
            </div>
          </div>
        )}

        <input
          hidden
          type="file"
          accept="image/*"
          ref={uploadPic}
          required={required}
          onChange={(e) => HandleChange(e)}
          {...props}
        />
      </div>
    </div>
  );
}

export default CustomUploadProfile;
