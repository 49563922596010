import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import DesktopNavigator from "./screens/web-view/navigator";
import PreloaderContext from "./context/preloaderContext";
import Preloader from "./components/preloader";
import AlertContext from "./context/alertContext";
import { Provider } from "react-redux";
import PetStore from "./store";

const App = () => {
  const [showPreloader, setShowPreloader] = useState(false);
  return (
    <Provider store={PetStore}>
      <BrowserRouter>
        <PreloaderContext.Provider value={{ showPreloader, setShowPreloader }}>
          <AlertContext.Provider>
            <DesktopNavigator />
            <Preloader />
          </AlertContext.Provider>
        </PreloaderContext.Provider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
