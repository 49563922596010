import React, { useEffect, useState } from "react";
import Header from "../../../components/header";
import CustomSelect from "../../../components/customSelect";
import CustomButton from "../../../components/customBtn";
import { useNavigate } from "react-router-dom";
import { SetItemsToLocalStorage } from "../../../helpers/handleLocalStorage";
import ImageSelectBox from "../../../components/imgSelectbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const bgImg = require("../../../assets/images/png/pet-bg-img.png");
function FindPetPartnetDeskTop() {
  const dogFromTearPaper = require("../../../assets/images/png/dog-from-tear-sheet.png");
  const [inputValues, setInputValues] = useState({
    type: "",
    breed: "",
  });
  const navigate = useNavigate();
  const [petType, setPetType] = useState([
    {
      label: require("../../../assets/images/png/cat-icon.png"),
      value: "cat",
    },
    {
      label: require("../../../assets/images/png/dog-icon.png"),
      value: "dog",
    },
    {
      label: require("../../../assets/images/png/rabbit-icon.png"),
      value: "rabbit",
    },
  ]);

  const [breedType, setBreedType] = useState([
    {
      label: "Puppy",
      value: "puppy",
    },
    {
      label: "Puppy1",
      value: "puppy1",
    },
    {
      label: "Puppy2",
      value: "puppy2",
    },
  ]);

  useEffect(() => {
    console.log("changed type");
    // fetch breed list from db
  }, [inputValues.type]);

  const HandleInputChange = (key, value) => {
    setInputValues({ ...inputValues, [key]: value });
  };

  const HandleFindList = () => {
    SetItemsToLocalStorage(
      "searchPetPartner",
      JSON.stringify({ petInfo: inputValues })
    );
    navigate(
      `/find-parnter/pet-list/${inputValues.type}/${inputValues.breed}/1`
    );
  };
  return (
    <>
      <div className="flex flex-col bg-secondary space-y-6 justify-center items-center fixed top-0 bottom-0 left-0 right-0">
        <div className="absolute top-5 right-0 left-0">
          <Header navigate={navigate} />
        </div>
        {/* background images */}
        <div
          className="fixed top-0 bottom-0 left-0 right-0 z-[-1] "
          style={{
            // backgroundImage: `url(${bgImg})`,
            backgroundSize: "20vw",
            opacity: "30%",
          }}
        ></div>
        <div className="">
          {" "}
          <h2 className="font-bold text-white text-left text-3xl mb-3">
            Select Pet Type
          </h2>
          <ImageSelectBox
            height={120}
            masterDivCLass={"flex flex-row space-x-5"}
            outerDivClass={"border p-3 border-1 border-dark rounded-lg"}
            options={petType}
            onSelect={(data) => HandleInputChange("type", data)}
            isSingleSelect={true}
          />
        </div>
        <div className="flex flex-col space-y-7">
          {inputValues.type && inputValues.type.length > 0 && (
            <CustomSelect
              label={"Select Pet Breed"}
              labelClass={"text-3xl text-light font-bold"}
              options={breedType}
              selectClass={
                "px-6 min-w-[20vw] rounded-lg py-2 text-2xl font-bold"
              }
              outerdivClass={"flex flex-col space-y-3"}
              onChange={(value) => HandleInputChange("breed", value)}
              defaultLabel={"Select"}
            />
          )}
          {inputValues.breed && (
            <CustomButton
              className={
                "bg-dark self-center text-light px-10 py-2 rounded-lg text-xl font-bold"
              }
              label={
                <p>
                  Find pet partner{" "}
                  <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                </p>
              }
              onClick={() => HandleFindList()}
            />
          )}
        </div>

        <img
          src={dogFromTearPaper}
          alt=""
          className="fixed top-[50%] bottom-[50%] right-20 transform -translate-y-1/2 h-[28vh]"
        />
      </div>
    </>
  );
}

export default FindPetPartnetDeskTop;
