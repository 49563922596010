import React from "react";
import "./petDetailsSlideDesktop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
function PetDetailsSlideDesktop({
  isOpen,
  divClassName,
  children,
  width,
  sideMenuClass,
  paramToRemove,
}) {
  const navigate = useNavigate();
  const HandleToggleDetails = () => {
    // Remove the specified parameter from the URL
    const newSearch = window.location.pathname.replace(/[^/]*$/, "");
    navigate(newSearch, { replace: true });
  };
  return (
    <div
      className={`side-menu-right bg-ternary px-7 ${sideMenuClass}`}
      style={{ right: `${isOpen ? "0" : `-${width}`}`, width }}
    >
      <div className="py-5 text-black text-xl font-bold">
        <FontAwesomeIcon
          className=" cursor-pointer"
          icon={faXmark}
          onClick={() => HandleToggleDetails()}
        />
      </div>
      <div className={`${divClassName} menu-content-right`}>{children}</div>
    </div>
  );
}

export default PetDetailsSlideDesktop;
