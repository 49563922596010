import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
const CustomSelectWithSearch = ({
  outerDivClass,
  labelClass,
  label,
  required,
  defaultLabel,
  className,
  inputClassName,
  defaultValue,
  onChange,
  options,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    defaultValue || defaultLabel || ""
  );
  const [showingoptions, setShowingOptions] = useState(options || []);
  const HandleSelect = (value) => {
    setSelectedOption(value.label);
    setShowOptions(false);
    onChange(value.value);
    setShowingOptions(options);
  };

  const HandleFilter = (value) => {
    if (value !== "" && value) {
      const filterData = options.filter((data) => {
        return data.value.includes(value) || data.label.includes(value);
      });
      setShowingOptions(filterData);
    } else {
      setShowingOptions(options);
    }
  };
  return (
    <div className={outerDivClass}>
      <label className={labelClass}>
        {label}
        {required && <span className="text-md text-red-600 font-bold">*</span>}
      </label>
      <div className="relative">
        <div
          className={`flex ${className}`}
          onClick={() => setShowOptions(!showOptions)}
        >
          <div className={`w-full pl-3 ${inputClassName}`}>
            {selectedOption}
          </div>
          <span className={` flex items-center px-2 text-gray-500 font-bold`}>
            {!showOptions ? (
              <FontAwesomeIcon icon={faAngleDown} />
            ) : (
              <FontAwesomeIcon icon={faAngleUp} />
            )}
          </span>
        </div>
        {showOptions && (
          <div className="border border-dark p-1">
            <input
              className="border border-gray-400 w-full py-1 pl-2"
              placeholder="Search..."
              onChange={(e) => HandleFilter(e.target.value)}
            />
          </div>
        )}
        {showOptions && (
          <div className="absolute right-0 left-0 rounded-lg bg-white overflow-y-auto max-h-[20vh]">
            {showingoptions?.map((items) => (
              <div
                onClick={() => HandleSelect(items)}
                className="cursor-pointer hover:bg-blue-200 pl-2 py-1.5 border border-gray-400 "
              >
                {items.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelectWithSearch;
