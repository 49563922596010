import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/customBtn";
import { useNavigate, useParams } from "react-router-dom";
import {
  NewPetAddPetInfoStep1,
  NewPetAddPetInfoStep2,
  NewPetAddPetInfoStep3,
  NewPetAddPetInfoStep4,
  NewPetAddPetInfoStep5,
  NewPetAddPetInfoStep6,
} from "./petInfoForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  SetItemsToLocalStorage,
  deleteItemsFromLocalStorage,
  getItemsFromLocalStorage,
} from "../../../helpers/handleLocalStorage";
function AddNewPetDeskTop() {
  const navigate = useNavigate();
  const { step } = useParams();
  const [formInput, setFormInput] = useState({
    petname: "",
    petgender: "",
    pettype: "",
    petbreedtype: "",
    petbreed: "",
    petweight: "",
    petsize: "",
    petbirth: "",
    petcolor: "",
    petimported: 0,
    importedcountry: "",
    petdiffabled: 0,
    diffabledtext: "",
    petdoorno: "",
    petstreetname: "",
    petcity: "",
    petstate: "",
    petcountry: "India",
    samepersoncontact: true,
    petcontactperson: "",
    petcontactnumber: "",
    petproofvideo: "",
    petvaccinated: 1,
    petbreedingfirsttime: "",
    petcertified: "",
    petcertifiedby: "",
    certificateimg: "",
    petprofileimage: "",
    petgallery: [],
  });
  const [formCompletedStatus, setFormCompletedStatus] = useState({
    form1: false,
    form2: false,
    form3: false,
    form4: false,
    form5: false,
    form6: false,
  });

  const SyncLocalStoragedata = async () => {
    const localData = await getItemsFromLocalStorage("AddPetFormValues");
    const LocalFormStatus = await getItemsFromLocalStorage(
      "AddPetFormComplitionStatus"
    );
    if (LocalFormStatus) setFormCompletedStatus(JSON.parse(LocalFormStatus));
    if (localData) setFormInput(JSON.parse(localData));
  };
  useEffect(() => {
    SyncLocalStoragedata();
  }, []);
  const handleInput = (name, value) => {
    setFormInput({ ...formInput, [name]: value });
  };
  const HandleFormSubmit = (e, step) => {
    e.preventDefault();
    setFormCompletedStatus({ ...formCompletedStatus, [`form${step}`]: true });
    SetItemsToLocalStorage("AddPetFormValues", JSON.stringify(formInput));
    SetItemsToLocalStorage(
      "AddPetFormComplitionStatus",
      JSON.stringify({ ...formCompletedStatus, [`form${step}`]: true })
    );
    if (formCompletedStatus.form5) {
      deleteItemsFromLocalStorage("AddPetFormValues");
      deleteItemsFromLocalStorage("AddPetFormComplitionStatus");
      navigate("/add-pet/thankyou");
    } else navigate(`/add-pet/${step + 1}`);
  };

  return (
    <div className="flex justify-center items-center fixed top-0 bottom-0 left-0 right-0">
      {/* background images */}
      <div
        className="fixed top-0 bottom-0 left-0 right-0 z-[-1] "
        style={{
          backgroundSize: "20vw",
          opacity: "30%",
        }}
      ></div>
      <div className="p-4 rounded-lg overflow-auto my-6 w-[40vw] max-h-[100vh]">
        <div>
          <p className="text-gray-500 font-bold text-sm">
            Step {parseInt(step)}
          </p>
          <div className="flex justify-start items-center my-1">
            <div
              onClick={() => navigate("/add-pet/1")}
              className={`w-5 h-5 ${
                parseInt(step) > 1 || formCompletedStatus.form1
                  ? "bg-secondary"
                  : parseInt(step) === 1
                  ? "bg-blue-600"
                  : "bg-gray-400 "
              } bg-blue-600 rounded-full cursor-pointer text-white flex items-center justify-center font-bold text-lg`}
            >
              {(parseInt(step) > 1 || formCompletedStatus.form1) && (
                <FontAwesomeIcon icon={faCheck} className="text-sm font-bold" />
              )}
            </div>
            <div className="h-0.5 w-[2vw] mx-1 bg-gray-400"></div>
            <div
              onClick={() => {
                if (formCompletedStatus.form2 || formCompletedStatus.form1)
                  navigate("/add-pet/2");
              }}
              className={`w-5 h-5 ${
                parseInt(step) > 2 || formCompletedStatus.form2
                  ? "bg-secondary"
                  : parseInt(step) === 2
                  ? "bg-blue-600"
                  : "bg-gray-400 "
              } rounded-full cursor-pointer text-white flex items-center justify-center font-bold text-lg`}
            >
              {(parseInt(step) > 2 || formCompletedStatus.form2) && (
                <FontAwesomeIcon icon={faCheck} className="text-sm font-bold" />
              )}
            </div>
            <div className="h-0.5 w-[2vw] mx-1 bg-gray-400"></div>
            <div
              onClick={() => {
                if (formCompletedStatus.form3 || formCompletedStatus.form2)
                  navigate("/add-pet/3");
              }}
              className={`w-5 h-5 ${
                parseInt(step) > 3 || formCompletedStatus.form3
                  ? "bg-secondary"
                  : parseInt(step) === 3
                  ? "bg-blue-600"
                  : "bg-gray-400 "
              } rounded-full cursor-pointer text-white flex items-center justify-center font-bold text-lg`}
            >
              {(parseInt(step) > 3 || formCompletedStatus.form3) && (
                <FontAwesomeIcon icon={faCheck} className="text-sm font-bold" />
              )}
            </div>
            <div className="h-0.5 w-[2vw] mx-1 bg-gray-400"></div>
            <div
              onClick={() => {
                if (formCompletedStatus.form4 || formCompletedStatus.form3)
                  navigate("/add-pet/4");
              }}
              className={`w-5 h-5 ${
                parseInt(step) > 4 || formCompletedStatus.form4
                  ? "bg-secondary"
                  : parseInt(step) === 4
                  ? "bg-blue-600"
                  : "bg-gray-400 "
              } rounded-full cursor-pointer text-white flex items-center justify-center font-bold text-lg`}
            >
              {(parseInt(step) > 4 || formCompletedStatus.form4) && (
                <FontAwesomeIcon icon={faCheck} className="text-sm font-bold" />
              )}
            </div>
            <div className="h-0.5 w-[2vw] mx-1 bg-gray-400"></div>
            <div
              onClick={() => {
                if (formCompletedStatus.form5 || formCompletedStatus.form4)
                  navigate("/add-pet/5");
              }}
              className={`w-5 h-5 ${
                parseInt(step) > 5 || formCompletedStatus.form5
                  ? "bg-secondary"
                  : parseInt(step) === 5
                  ? "bg-blue-600"
                  : "bg-gray-400 "
              } rounded-full cursor-pointer text-white flex items-center justify-center font-bold text-lg`}
            >
              {(parseInt(step) > 5 || formCompletedStatus.form5) && (
                <FontAwesomeIcon icon={faCheck} className="text-sm font-bold" />
              )}
            </div>
            <div className="h-0.5 w-[2vw] mx-1 bg-gray-400"></div>
            <div
              onClick={() => {
                if (formCompletedStatus.form6 || formCompletedStatus.form5)
                  navigate("/add-pet/6");
              }}
              className={`w-5 h-5 ${
                parseInt(step) > 6 || formCompletedStatus.form6
                  ? "bg-secondary"
                  : parseInt(step) === 6
                  ? "bg-blue-600"
                  : "bg-gray-400 "
              } rounded-full cursor-pointer text-white flex items-center justify-center font-bold text-lg`}
            >
              {(parseInt(step) > 6 || formCompletedStatus.form6) && (
                <FontAwesomeIcon icon={faCheck} className="text-sm font-bold" />
              )}
            </div>
          </div>
        </div>
        {/* Form 1 screen */}
        {parseInt(step) === 1 && (
          <form
            onSubmit={(e) => {
              HandleFormSubmit(e, 1);
            }}
          >
            <div className="my-4 py-5 h-[73vh] overflow-y-scroll flex items-center">
              <div className="w-full">
                <NewPetAddPetInfoStep1
                  handleInput={handleInput}
                  inputValues={formInput}
                  formCompletedStatus={formCompletedStatus}
                />
              </div>
            </div>
            <div className="text-right">
              <CustomButton
                label={
                  <>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </>
                }
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
            </div>
          </form>
        )}
        {/* Form 2 screen */}
        {parseInt(step) === 2 && (
          <form
            onSubmit={(e) => {
              HandleFormSubmit(e, 2);
            }}
          >
            <div className="my-4 py-5  h-[73vh] overflow-y-auto flex items-center">
              <div className="w-full">
                <NewPetAddPetInfoStep2
                  handleInput={handleInput}
                  inputValues={formInput}
                  formCompletedStatus={formCompletedStatus}
                  navigate={navigate}
                />
              </div>
            </div>
            <div className="text-right mt-2 space-x-4">
              <CustomButton
                type="button"
                onClick={() => navigate("/add-pet/1")}
                label={<FontAwesomeIcon icon={faArrowLeft} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
              <CustomButton
                label={<FontAwesomeIcon icon={faArrowRight} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
            </div>
          </form>
        )}
        {/* Form 3 screen */}
        {parseInt(step) === 3 && (
          <form
            onSubmit={(e) => {
              HandleFormSubmit(e, 3);
            }}
          >
            <div className="my-4 py-5  h-[73vh] overflow-y-auto flex items-center">
              <div className="w-full">
                <NewPetAddPetInfoStep3
                  handleInput={handleInput}
                  inputValues={formInput}
                  formCompletedStatus={formCompletedStatus}
                  navigate={navigate}
                />
              </div>
            </div>
            <div className="text-right mt-2 space-x-4">
              <CustomButton
                type="button"
                onClick={() => navigate("/add-pet/2")}
                label={<FontAwesomeIcon icon={faArrowLeft} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
              <CustomButton
                label={<FontAwesomeIcon icon={faArrowRight} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
            </div>
          </form>
        )}
        {/* Form 4 screen */}
        {parseInt(step) === 4 && (
          <form
            onSubmit={(e) => {
              HandleFormSubmit(e, 4);
            }}
          >
            <div className="my-4 py-5  h-[73vh] overflow-y-auto  flex items-center">
              <div className="w-full">
                <h2 className="text-black  font-bold text-2xl text-center mb-4">
                  Pet video verification
                </h2>
                <NewPetAddPetInfoStep4
                  handleInput={handleInput}
                  inputValues={formInput}
                  formCompletedStatus={formCompletedStatus}
                  navigate={navigate}
                />
              </div>
            </div>
            <div className="text-right mt-2 space-x-4">
              <CustomButton
                type="button"
                onClick={() => navigate("/add-pet/3")}
                label={<FontAwesomeIcon icon={faArrowLeft} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
              <CustomButton
                label={<FontAwesomeIcon icon={faArrowRight} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
            </div>
          </form>
        )}
        {/* Form 5 screen */}
        {parseInt(step) === 5 && (
          <form
            onSubmit={(e) => {
              HandleFormSubmit(e, 5);
            }}
          >
            <div className="my-4 py-5  h-[73vh] overflow-y-auto  flex items-center">
              <div className="w-full">
                <NewPetAddPetInfoStep5
                  handleInput={handleInput}
                  inputValues={formInput}
                  formCompletedStatus={formCompletedStatus}
                  navigate={navigate}
                  setFormInput={setFormInput}
                />
              </div>
            </div>
            <div className="text-right mt-2 space-x-4">
              <CustomButton
                type="button"
                onClick={() => navigate("/add-pet/4")}
                label={<FontAwesomeIcon icon={faArrowLeft} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
              <CustomButton
                label={<FontAwesomeIcon icon={faArrowRight} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
            </div>
          </form>
        )}
        {/* Form 6 screen */}
        {parseInt(step) === 6 && (
          <form
            onSubmit={(e) => {
              HandleFormSubmit(e, 6);
            }}
          >
            <div className="my-4 py-5  h-[73vh] overflow-y-auto  flex items-center">
              <div className="w-full">
                <NewPetAddPetInfoStep6
                  handleInput={handleInput}
                  inputValues={formInput}
                  formCompletedStatus={formCompletedStatus}
                  navigate={navigate}
                />
              </div>
            </div>
            <div className="text-right mt-2 space-x-4">
              <CustomButton
                type="button"
                onClick={() => navigate("/add-pet/5")}
                label={<FontAwesomeIcon icon={faArrowLeft} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
              <CustomButton
                label={<FontAwesomeIcon icon={faCheck} />}
                className={
                  "bg-secondary text-white rounded-3xl text-lg h-12 w-12 font-bold"
                }
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default AddNewPetDeskTop;
