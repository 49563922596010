export const SetItemsToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
  return true;
};

export const getItemsFromLocalStorage = (key) => {
  const localData = localStorage.getItem(key);
  return localData;
};

export const deleteItemsFromLocalStorage = (key) => {
  localStorage.removeItem(key);
  return true;
};
