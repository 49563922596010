import React, { useState } from "react";

const CustomCheckbox = ({
  options,
  outerDivClass,
  label,
  required,
  labelClass,
  defaultValue,
  onChange,
  checkClass,
  SelectlabelClass,
  name,
}) => {
  let CheckedValues = defaultValue ? defaultValue : [];
  const handleCheckboxChange = (value) => {
    if (!CheckedValues.includes(value)) {
      CheckedValues.push(value);
    } else {
      const presentIndex = CheckedValues.filter((item) => item !== value);
      CheckedValues.splice(presentIndex, 1);
    }
    onChange(CheckedValues);
  };

  return (
    <div className={outerDivClass}>
      <label className={labelClass}>
        {label}{" "}
        {required && <span className="text-md text-red-600 font-bold">*</span>}
      </label>
      <div className="flex flex-row space-x-5">
        {options &&
          options.map((items, index) => (
            <div className="flex flex-row items-center space-x-3">
              <input
                type="checkbox"
                id={index}
                className={checkClass}
                name={name + index}
                onChange={() => handleCheckboxChange(items.value)}
                defaultChecked={
                  defaultValue ? defaultValue.includes(items.value) : false
                }
                required={required}
              />
              <label className={SelectlabelClass}>{items.label}</label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomCheckbox;
