import React, { useRef } from "react";
import CustomInput from "../../../components/customInput";
import CustomSelect from "../../../components/customSelect";
import CustomRadioBtn from "../../../components/customRadio";
import CustomSelectWithSearch from "../../../components/customSelectWithSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import CustomUploadProfile from "../../../components/customUploadProfile";
const countrylist = require("../../../json/country.json");
const tempSelect = [
  {
    label: "Dog",
    value: "dog",
  },
  {
    label: "cat",
    value: "cat",
  },
];
export const NewPetAddPetInfoStep1 = ({ handleInput, inputValues }) => {
  return (
    <div className="bg-white rounded-lg py-7">
      <div className="space-y-8">
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg "}
          label={"Name"}
          inputClassName={"py-1.5 rounded-lg bg-gray-100 font-medium"}
          labelClass={"text-lg font-bold"}
          name={"petname"}
          type={"text"}
          placeholder={"Called by..."}
          value={inputValues?.petname}
          defaultValue={inputValues?.petname}
          required={true}
          onChange={(value) => handleInput("petname", value)}
        />

        <CustomSelect
          outerdivClass={" flex justify-between items-center"}
          label={"Pet type"}
          selectClass={
            "border border-black rounded-md py-1.5  w-[20vw] bg-gray-100 font-medium"
          }
          labelClass={"text-lg font-bold"}
          options={tempSelect}
          required={true}
          defaultValue={inputValues?.pettype}
          defaultLabel={"Select type"}
          onChange={(value) => handleInput("pettype", value)}
        />
        <CustomRadioBtn
          options={[
            {
              label: "Pure breed",
              value: "pure",
            },
            {
              label: "Mixed breed (Cross)",
              value: "cross",
            },
          ]}
          label={"Breed type"}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          name={"petbreedtype"}
          defaultValue={inputValues?.petbreedtype}
          required={true}
          radioClass={"h-4 w-4"}
          onChange={(value) => handleInput("petbreedtype", value)}
        />
        <CustomSelect
          outerdivClass={" flex justify-between items-center"}
          label={"Breed"}
          selectClass={
            "border border-black rounded-md py-1.5  w-[20vw] bg-gray-100 font-medium"
          }
          labelClass={"text-lg font-bold"}
          options={tempSelect}
          defaultLabel={"Select breed"}
          defaultValue={inputValues?.petbreed}
          required={true}
          onChange={(value) => handleInput("petbreed", value)}
        />
        <CustomRadioBtn
          options={[
            {
              label: "Male",
              value: "male",
            },
            {
              label: "Female",
              value: "female",
            },
          ]}
          label={"Gender"}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          name={"petgender"}
          defaultValue={inputValues?.petgender}
          required={true}
          radioClass={"h-4 w-4"}
          onChange={(value) => handleInput("petgender", value)}
        />
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={"Weight"}
          inputClassName={"py-1.5 rounded-lg bg-gray-100 font-medium"}
          labelClass={"text-lg font-bold"}
          name={"petweight"}
          type={"text"}
          suffix={"kg"}
          value={inputValues?.petweight}
          defaultValue={inputValues?.petweight}
          required={true}
          onChange={(value) => handleInput("petweight", value)}
        />
      </div>
    </div>
  );
};

export const NewPetAddPetInfoStep2 = ({
  handleInput,
  inputValues,
  formCompletedStatus,
  navigate,
}) => {
  if (!formCompletedStatus.form1) navigate(-1);
  return (
    <div className="bg-white rounded-lg py-7">
      <div className="space-y-8">
        <CustomRadioBtn
          options={[
            {
              label: "Large",
              value: "large",
            },
            {
              label: "Medium",
              value: "medium",
            },
            {
              label: "Baby",
              value: "baby",
            },
          ]}
          label={`How large is ${inputValues?.petname} ?`}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          name={"petsize"}
          defaultValue={inputValues?.petsize}
          required={true}
          radioClass={"h-4 w-4"}
          onChange={(value) => handleInput("petsize", value)}
        />
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={`How old is ${inputValues?.petname} ?`}
          inputClassName={"py-1.5 rounded-lg bg-gray-100 font-medium"}
          labelClass={"text-lg font-bold"}
          name={"petbirth"}
          type={"text"}
          suffix={"months"}
          required={true}
          value={inputValues?.petbirth}
          defaultValue={inputValues?.petbirth}
          onChange={(value) => handleInput("petbirth", value)}
        />

        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={`${inputValues?.petname} color shade?`}
          inputClassName={"py-1.5 rounded-lg bg-gray-100 font-medium"}
          labelClass={"text-lg font-bold"}
          name={"petcolor"}
          type={"text"}
          required={true}
          value={inputValues?.petcolor}
          defaultValue={inputValues?.petcolor}
          onChange={(value) => handleInput("petcolor", value)}
        />
        <CustomRadioBtn
          options={[
            {
              label: "Yes",
              value: 1,
            },
            {
              label: "No",
              value: 0,
            },
          ]}
          label={"Imported from other country"}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          defaultValue={inputValues?.petimported}
          name={"petimported"}
          radioClass={"h-4 w-4"}
          onChange={(value) => handleInput("petimported", value)}
        />
        {inputValues?.petimported ? (
          <CustomSelectWithSearch
            outerDivClass={" flex justify-between items-center"}
            className={
              "border border-black w-[20vw] rounded-lg cursor-pointer bg-gray-100 font-medium"
            }
            label={"Can you specify that?"}
            inputClassName={"py-1.5 rounded-t-lg bg-gray-100 font-medium"}
            labelClass={"text-lg font-bold"}
            name={"importedcountry"}
            options={countrylist}
            defaultValue={inputValues?.importedcountry}
            defaultLabel={"Select Country"}
            onChange={(value) => handleInput("importedcountry", value)}
          />
        ) : (
          <></>
        )}
        <CustomRadioBtn
          options={[
            {
              label: "Yes",
              value: 1,
            },
            {
              label: "No",
              value: 0,
            },
          ]}
          label={"Differently abled"}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          defaultValue={inputValues?.petdiffabled}
          name={"petdiffabled"}
          radioClass={"h-4 w-4"}
          onChange={(value) => handleInput("petdiffabled", value)}
        />
        {inputValues?.petdiffabled ? (
          <CustomInput
            outerDivClass={" flex justify-between items-center"}
            className={"border border-black w-[20vw] rounded-lg"}
            label={"Can you specify that?"}
            inputClassName={"py-1.5 rounded-lg bg-gray-100 font-medium"}
            labelClass={"text-lg font-bold"}
            name={"diffabledtext"}
            type={"text"}
            defaultValue={inputValues?.diffabledtext}
            onChange={(value) => handleInput("diffabledtext", value)}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const NewPetAddPetInfoStep3 = ({
  handleInput,
  inputValues,
  formCompletedStatus,
  navigate,
}) => {
  if (!formCompletedStatus.form2) navigate(-1);
  return (
    <div className="bg-white rounded-lg py-7">
      <div className="space-y-8">
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={"Plot/Door No"}
          inputClassName={"py-1.5 rounded-lg"}
          labelClass={"text-lg font-bold"}
          name={"petdoorno"}
          type={"text"}
          placeholder={"Plot/Door No"}
          required={true}
          value={inputValues?.petdoorno}
          defaultValue={inputValues?.petdoorno}
          onChange={(value) => handleInput("petdoorno", value)}
        />
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={"Address"}
          inputClassName={"py-1.5 rounded-lg"}
          labelClass={"text-lg font-bold"}
          name={"petstreetname"}
          type={"text"}
          placeholder={"Street / Road name"}
          required={true}
          value={inputValues?.petstreetname}
          defaultValue={inputValues?.petstreetname}
          onChange={(value) => handleInput("petstreetname", value)}
        />
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={"City"}
          inputClassName={"py-1.5 rounded-lg"}
          labelClass={"text-lg font-bold"}
          name={"petcity"}
          type={"text"}
          placeholder={"City"}
          required={true}
          value={inputValues?.petcity}
          defaultValue={inputValues?.petcity}
          onChange={(value) => handleInput("petcity", value)}
        />
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={"State"}
          inputClassName={"py-1.5 rounded-lg"}
          labelClass={"text-lg font-bold"}
          name={"petstate"}
          type={"text"}
          placeholder={"Street / Road name"}
          required={true}
          value={inputValues?.petstate}
          defaultValue={inputValues?.petstate}
          onChange={(value) => handleInput("petstate", value)}
        />
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={"Country"}
          inputClassName={"py-1.5 rounded-lg"}
          labelClass={"text-lg font-bold"}
          name={"petcountry"}
          type={"text"}
          placeholder={"Street / Road name"}
          disabled={true}
          defaultValue={inputValues?.petcountry}
          onChange={(value) => handleInput("petcountry", value)}
        />
        <div className="flex flex-row space-x-5 items-center">
          <input
            type="checkbox"
            className="w-4 h-4"
            name="samepersoncontact"
            defaultChecked={inputValues?.samepersoncontact}
            onClick={(e) => handleInput("samepersoncontact", e.target.checked)}
          />
          <label htmlFor="contact" className="text-lg font-bold">
            Contact my mobile
          </label>
        </div>
        {inputValues?.samepersoncontact === false ? (
          <>
            <CustomInput
              outerDivClass={" flex justify-between items-center"}
              className={"border border-black w-[20vw] rounded-lg"}
              label={"Contact person name"}
              inputClassName={"py-1.5 rounded-lg"}
              labelClass={"text-lg font-bold"}
              name={"petcontactperson"}
              type={"text"}
              placeholder={"Person name"}
              required={true}
              value={inputValues?.petcontactperson}
              defaultValue={inputValues?.petcontactperson}
              onChange={(value) => handleInput("petcontactperson", value)}
            />
            <CustomInput
              outerDivClass={" flex justify-between items-center"}
              className={"border border-black w-[20vw] rounded-lg"}
              label={"Contact number"}
              inputClassName={"py-1.5 rounded-lg"}
              labelClass={"text-lg font-bold"}
              name={"petcontactnumber"}
              type={"text"}
              placeholder={"Mobile number"}
              required={true}
              value={inputValues?.petcontactnumber}
              defaultValue={inputValues?.petcontactnumber}
              onChange={(value) => handleInput("petcontactnumber", value)}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const NewPetAddPetInfoStep4 = ({
  handleInput,
  inputValues,
  formCompletedStatus,
  navigate,
}) => {
  if (!formCompletedStatus.form3) navigate(-1);
  const videoUploadRef = useRef();
  return (
    <div className="bg-white rounded-lg py-7">
      <div className="space-y-8">
        <p className="text-gray-700 italic">
          We require profile verification to ensure that the pet is currently in
          your care.
        </p>
        <div className="bg-orange-200 p-4 rounded">
          <p className="text-orange-800 font-bold mb-3">Note:</p>
          <p className="text-orange-800">
            You must display a piece of paper clearly showing{" "}
            <span className="font-bold">today's date</span> along with your{" "}
            <span className="font-bold">pet in the video.</span>
            Otherwise, the video will be rejected.
          </p>
        </div>

        <ul className="list-circle">
          <li className="text-gray-600">
            We will not share this video with anyone else; it will remain
            private.
          </li>
          <li className="text-gray-600 mt-3">
            The video should be a minimum of 20 seconds and a maximum of 40
            seconds in duration, and it should be of good quality.
          </li>
        </ul>
        <p className="text-secondary font-bold ">
          Upload video<span className="text-md text-red-600 font-bold">*</span>
        </p>
        <div
          onClick={() => videoUploadRef.current.click()}
          className="border-2 border-secondary cursor-pointer bg-gray-200 px-4 py-8 flex justify-center items-center max-w-[9vw] shadow-lg"
        >
          <FontAwesomeIcon icon={faUpload} className="h-6 text-secondary" />
          <input
            ref={videoUploadRef}
            type="file"
            accept="video/*"
            className="hidden"
            name="petproofvideo"
          />
        </div>
      </div>
    </div>
  );
};

export const NewPetAddPetInfoStep5 = ({
  handleInput,
  inputValues,
  formCompletedStatus,
  navigate,
  setFormInput,
}) => {
  if (!formCompletedStatus.form4) navigate(-1);
  return (
    <div className="bg-white rounded-lg py-7">
      <div className="space-y-8">
        <CustomRadioBtn
          options={[
            {
              label: "Yes",
              value: 1,
            },
            {
              label: "No",
              value: 0,
            },
          ]}
          label={"Vaccinated"}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          defaultValue={inputValues?.petvaccinated}
          name={"petvaccinated"}
          radioClass={"h-4 w-4"}
          required={true}
          onChange={(value) => handleInput("petvaccinated", value)}
        />
        <CustomRadioBtn
          options={[
            {
              label: "Yes",
              value: 1,
            },
            {
              label: "No",
              value: 0,
            },
          ]}
          label={"Breeding for the first time"}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          defaultValue={inputValues?.petbreedingfirsttime}
          name={"petbreedingfirsttime"}
          radioClass={"h-4 w-4"}
          required={true}
          onChange={(value) => handleInput("petbreedingfirsttime", value)}
        />
        <CustomRadioBtn
          options={[
            {
              label: "Yes",
              value: 1,
            },
            {
              label: "No",
              value: 0,
            },
          ]}
          label={"Certified"}
          outerDivClass={" flex justify-between items-center"}
          labelClass={"text-lg font-bold"}
          SelectlabelClass={"text-lg"}
          defaultValue={inputValues?.petcertified}
          name={"petcertified"}
          radioClass={"h-4 w-4"}
          required={true}
          onChange={(value) => handleInput("petcertified", value)}
        />
        {inputValues?.petcertified ? (
          <>
            <CustomSelect
              outerdivClass={" flex justify-between items-center"}
              label={"Certified by"}
              selectClass={
                "border border-black rounded-md py-1.5  w-[20vw] bg-gray-100 font-medium"
              }
              labelClass={"text-lg font-bold"}
              options={tempSelect}
              defaultLabel={"Select breed"}
              defaultValue={inputValues?.petcertifiedby}
              required={true}
              onChange={(value) => handleInput("petcertifiedby", value)}
            />

            {inputValues?.certificateimg ? (
              <div className="flex justify-between items-center">
                <label htmlFor="" className="text-lg font-bold">
                  Upload certificate
                </label>
                <p className="w-[20vw]">
                  {inputValues?.certificateimg}
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() =>
                      setFormInput({ ...inputValues, certificateimg: null })
                    }
                    className="ml-3 text-red-500 cursor-pointer"
                  />
                </p>
              </div>
            ) : (
              <CustomInput
                outerDivClass={" flex justify-between items-center"}
                className={"border border-black w-[20vw] rounded-lg"}
                label={"Upload certificate"}
                inputClassName={"py-1.5 rounded-lg  "}
                labelClass={"text-lg font-bold"}
                name={"certificateimg"}
                type={"file"}
                multiple
                required={inputValues?.certificateimg ? false : true}
                accept=".pdf"
                hint={"Upload certificate in pdf format"}
                onChange={(value) => {
                  handleInput("certificateimg", value.toString());
                }}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const NewPetAddPetInfoStep6 = ({
  handleInput,
  inputValues,
  formCompletedStatus,
  navigate,
}) => {
  if (!formCompletedStatus.form5) navigate(-1);
  return (
    <div className="bg-white rounded-lg py-7">
      <div className="space-y-8">
        <CustomUploadProfile
          label={`Upload ${inputValues?.petname}'s DP`}
          labelClass={"text-lg font-bold"}
          required={true}
          outerDivClass={"flex justify-between items-center"}
          onChange={(e) => console.log("dp", e.target.files)}
        />
        <CustomInput
          outerDivClass={" flex justify-between items-center"}
          className={"border border-black w-[20vw] rounded-lg"}
          label={`Update ${inputValues?.petname}'s gallery (Optional)`}
          inputClassName={"py-1.5 rounded-lg  "}
          labelClass={"text-lg font-bold"}
          name={"petgallery"}
          type={"file"}
          multiple
          accept="image/*"
          defaultValue={inputValues?.petgallery}
          hint={`Upload ${inputValues?.petname} images to gallery`}
          onChange={(value) => handleInput("petgallery", value)}
        />
      </div>
    </div>
  );
};
