import React, { useEffect, useState } from "react";
import CustomSelect from "../../../components/customSelect";
import { getItemsFromLocalStorage } from "../../../helpers/handleLocalStorage";
import CustomRadioBtn from "../../../components/customRadio";
import CustomButton from "../../../components/customBtn";
import CustomMultiSelectDropdown from "../../../components/customMultiSelect";
import CustomCheckbox from "../../../components/customCheckbox";

function PetListFiltersDesktop() {
  const [defaultFilters, setDefaultFilters] = useState({});
  const [petType, setPetType] = useState([
    {
      label: "Dog",
      value: "dog",
    },
    {
      label: "Cat",
      value: "cat",
    },
    {
      label: "Dove",
      value: "dove",
    },
  ]);

  const [breedType, setBreedType] = useState([
    {
      label: "Puppy",
      value: "puppy",
    },
    {
      label: "Puppy1",
      value: "puppy1",
    },
    {
      label: "Puppy2",
      value: "puppy2",
    },
  ]);

  const [petsex, setPetsex] = useState([
    {
      label: "Any",
      value: "any",
    },
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    },
  ]);

  const [petAge, setPetAge] = useState([
    {
      label: "Any age",
      value: "any",
    },
    {
      label: "Baby",
      value: "baby",
    },
    {
      label: "Young",
      value: "young",
    },
  ]);

  const [locationList, setLocationList] = useState([
    { label: "Chennai", value: "chennai" },
    { label: "Mumbai", value: "mumbai" },
    { label: "Coimbatore", value: "coimbatore" },
    { label: "Trichy", value: "trichy" },
    { label: "Madurai", value: "madurai" },
    { label: "Bangalore", value: "bangalore" },
  ]);

  const [verificationStatusList, setVerificationStatusList] = useState([
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Certified",
      value: "certified",
    },
  ]);

  useEffect(() => {
    const searchPartnerInfo = getItemsFromLocalStorage("searchPetPartner");
    setDefaultFilters(JSON.parse(searchPartnerInfo));
  }, []);

  const HandlePetTypeInfoChange = (key, value) => {
  };
  return (
    <div className="p-5 space-y-9">
      <div>
        <p className="text-light text-xl font-bold">Your looking for: </p>
        <div className="flex flex-row my-4 justify-evenly">
          <CustomSelect
            label={"Pet type"}
            labelClass={"text-light text-lg px-3 "}
            options={petType}
            selectClass={"text-dark px-4 py-1 rounded-md"}
            defaultValue={defaultFilters?.petInfo?.type}
            onChange={(e) => HandlePetTypeInfoChange("type", e.target.value)}
          />
          <CustomSelect
            label={"Pet breed"}
            labelClass={"text-light text-lg px-3 "}
            options={breedType}
            selectClass={"text-dark px-4 py-1 rounded-md"}
            defaultValue={defaultFilters?.petInfo?.breed}
            onChange={(e) => HandlePetTypeInfoChange("breed", e.target.value)}
          />
        </div>
      </div>
      <div>
        <p className="text-light text-xl font-bold">Pet's sex: </p>
        <CustomRadioBtn
          name={"petSexOnList"}
          options={petsex}
          labelClass={"text-light text-lg mx-2"}
          defaultValue={"any"}
          radioClass={
            "form-radio h-5 cursor-pointer w-5 mr-2 focus:ring-indigo-500"
          }
          outerDivClass={"flex flex-row flex-wrap justify-evenly my-4 "}
          onChange={(value) => console.log("checked values", value)}
        />
      </div>
      <div>
        <p className="text-light text-xl font-bold">Pet's Age: </p>
        <CustomRadioBtn
          name={"petAgeOnList"}
          options={petAge}
          labelClass={"text-light text-lg mx-2"}
          defaultValue={"any"}
          radioClass={
            "form-radio h-5 cursor-pointer w-5 mr-2 focus:ring-indigo-500"
          }
          outerDivClass={"flex flex-row flex-wrap justify-evenly my-4 "}
          onChange={(value) => console.log("checked values", value)}
        />
      </div>
      <div>
        <p className="text-light text-xl font-bold">Verification status: </p>
        <CustomCheckbox
          options={verificationStatusList}
          labelClass={"text-light text-lg mx-2"}
          defaultValue={["any", "baby"]}
          checkboxClass={"form-radio h-5 cursor-pointer w-5 mr-2"}
          onChange={(value) => console.log("checked values", value)}
          outerDivClass={"flex flex-row flex-wrap justify-evenly my-4 "}
        />
      </div>
      <div>
        <p className="text-light text-xl font-bold">Preferred locations: </p>
        <div className="flex flex-col space-y-2 py-4">
          <CustomMultiSelectDropdown
            options={locationList}
            setSelectedItems={(value) =>
              console.log("selected countries", value)
            }
          />
        </div>
      </div>
    </div>
  );
}

export default PetListFiltersDesktop;
