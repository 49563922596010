import React from "react";
import CustomInput from "../../components/customInput";
import CustomButton from "../../components/customBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { SetItemsToLocalStorage } from "../../helpers/handleLocalStorage";

const bgImg = require("../../assets/images/png/pet-bg-img.png");
const googleIcon = require("../../assets/images/png/google-icon.png");
const callIcon = require("../../assets/images/png/call-img.png");
function LoginPage() {
  const navigate = useNavigate();
  const HandleLogin = (e) => {
    e.preventDefault();
    SetItemsToLocalStorage("AuthToken", true);
    navigate("/member/dashboard");
  };
  return (
    <div>
      {/* background images */}
      <div
        className="fixed top-0 bottom-0 left-0 right-0 z-[-1] "
        style={{
          // backgroundImage: `url(${bgImg})`,
          backgroundSize: "20vw",
          opacity: "30%",
        }}
      ></div>

      <div className="flex flex-row w-full fixed top-0 bottom-0 left-0 right-0 ">
        <div className="w-1/2 bg-primary"></div>
        <div className="w-1/2 bg-white flex items-center justify-center px-6">
          <div className="bg-white p-6 rounded-lg w-full max-w-[40vw]">
            <h2 className="text-secondary font-bold text-3xl text-center">
              Welcome!
            </h2>
            <form onSubmit={(e) => HandleLogin(e)}>
              <CustomInput
                labelClass={"text-black text-lg font-bold"}
                className={
                  "border border-gray-400 w-full pl-3 rounded-lg h-[5vh] bg-gray-100"
                }
                outerDivClass={"my-4"}
                label={"Email, mobile or username"}
                name={"email"}
                type={"email"}
                onChange={(e) => console.log(e)}
                required={true}
              />
              <CustomInput
                labelClass={"text-lg text-black font-bold"}
                className={
                  "border border-gray-400 pl-3 w-full rounded-lg h-[5vh] bg-gray-100"
                }
                outerDivClass={"my-4"}
                label={"Password"}
                name={"password"}
                type={"password"}
                onChange={(e) => console.log(e)}
                required={true}
              />
              <div className="text-center">
                <CustomButton
                  label={"Login"}
                  className={
                    "border uppercase border-gray-400 px-5 py-1 rounded text-lg bg-secondary text-white font-bold w-full"
                  }
                />
              </div>
            </form>
            <div className="my-4">
              <p>
                Don't have an account?{" "}
                <Link to={"/register"} className="text-secondary font-bold">
                  Sign up
                </Link>
              </p>
              <Link to={""} className="text-secondary font-bold">
                Forgot password?
              </Link>
            </div>
            <div className="">
              <div class="flex items-center justify-center">
                <hr class="w-full border-gray-300" />
                <span class="px-4 text-gray-800">Continue</span>
                <hr class="w-full border-gray-300" />
              </div>
              <div className="flex justify-center mt-4 space-x-5">
                <img src={googleIcon} className="h-7" alt="google-icon" />
                <img src={callIcon} className="h-7" alt="google-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-end min-w-[30vw] my-">
        <CustomButton
          label={
            <p>
              Skip for now <FontAwesomeIcon icon={faForward} className="px-1" />
            </p>
          }
          className={"bg-white px-5 font-bold py-2 rounded-lg"}
          onClick={() => navigate("/")}
        />
      </div> */}
    </div>
  );
}

export default LoginPage;
