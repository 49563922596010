import React, { useEffect, useState } from "react";
import PetListFiltersDesktop from "./listFilters";
import PetListViewDesktop from "./petListView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSearch,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import SideMenu from "../../../components/sidemenu/sidemenu";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPageNo,
  setTotalCount,
  setTotalPageNo,
} from "../../../store/findPartner/patnerList";
import HandlePageNumber from "../../../components/handlePageNumber";
import { setSortBy } from "../../../store/findPartner/appliedFilters";
import PetDetailsSlideDesktop from "../../../components/petDetailsSlideDesktop/petDetailsSlideDesktop";
import PetDetailsPageDesktop from "./petDetailsPage";
function PetListWithFiltersDesktop() {
  const { type, breed, pageNumber, petId } = useParams();
  const { currentPageNo, totalPagesNo, totalCount } = useSelector(
    (state) => state.findPartner
  );

  const { sortByList, sortBy } = useSelector(
    (state) => state.findPartnerFilter
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalCountRes = 100;
  useEffect(() => {
    dispatch(setCurrentPageNo(parseInt(pageNumber)));
    dispatch(setTotalCount(totalCountRes));
    dispatch(setTotalPageNo(Math.ceil(totalCountRes / 25)));
  }, [pageNumber]);

  const HandleChangePageNumber = (pageNo) => {
    navigate(`/find-parnter/pet-list/${type}/${breed}/${pageNo}`);
  };

  const HandleSortBy = (value) => {
    dispatch(setSortBy(value));
  };

  const [searchValue, setSearchValue] = useState("");
  const [toggleFilter, setToggleFilter] = useState(false);

  return (
    <>
      <div className="flex flex-row justify-center p-3">
        <div className="bg-light relative border border-gray-400">
          <input
            placeholder={"Search for pet"}
            type={"text"}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            className={"p-3 w-[20vw] font-bold text-md"}
          />
          <p className="absolute right-0 top-3 px-3 text-gray-800">
            {(searchValue === "" && (
              <FontAwesomeIcon icon={faSearch} className="h-5" />
            )) || (
              <FontAwesomeIcon
                icon={faXmarkCircle}
                className="h-5"
                onClick={() => setSearchValue("")}
              />
            )}
          </p>
        </div>
      </div>
      <div className="flex justify-left space-x-5 items-center mb-3 px-5">
        <p className="text-gray-500 text-md font-bold">
          Showing{" "}
          {currentPageNo * 25 - 23 < totalCount
            ? currentPageNo * 25 - 23
            : totalCount}{" "}
          – {currentPageNo * 25 < totalCount ? currentPageNo * 25 : totalCount}{" "}
          results of {totalCount} results
        </p>
        <button
          onClick={() => setToggleFilter(!toggleFilter)}
          className="border px-5 py-2 text-sm border-gray-300 bg-gray-200 font-bold"
        >
          <FontAwesomeIcon icon={faFilter} /> Filters
        </button>
      </div>
      <div className="flex justify-left space-x-5 items-center mb-3 px-5">
        <p className="font-bold text-md ">Sort By</p>
        {sortByList.map((items) => (
          <p
            onClick={() => {
              if (sortBy !== items.value) HandleSortBy(items.value);
            }}
            className={`text-sm font-bold cursor-pointer  ${
              sortBy === items.value && "cursor-text text-blue-500 underline"
            }`}
          >
            {items.label}
          </p>
        ))}
      </div>
      <div className="flex flex-row relative">
        <div className="">
          {toggleFilter ||
            (petId && (
              <div className="bg-[#000000BF] z-[2] fixed h-full left-0 right-0 bottom-0"></div>
            ))}
          <SideMenu
            isOpen={toggleFilter}
            divClassName={"bg-secondary h-full"}
            width={"25vw"}
            sideMenuClass={"z-[3]"}
          >
            <PetListFiltersDesktop
              toggleFilterBar={setToggleFilter}
              filterBarState={toggleFilter}
            />
          </SideMenu>
        </div>
        <div className={`bg-ternary w-full z-1 bg-center`}>
          <PetListViewDesktop showInfo={() => navigate("2")} />
        </div>
      </div>
      <PetDetailsSlideDesktop
        isOpen={petId}
        width={"65vw"}
        sideMenuClass={"z-[3] border border-gray-300 shadow shadow-xl bg-white"}
        paramToRemove={petId}
      >
        <PetDetailsPageDesktop />
      </PetDetailsSlideDesktop>
      <HandlePageNumber
        HandleChangePageNumber={HandleChangePageNumber}
        currentPageNo={currentPageNo}
        totalPagesNo={totalPagesNo}
      />
    </>
  );
}

export default PetListWithFiltersDesktop;
