import React from "react";
import { PetCardsDesktop } from "../../../components/petCards";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HandleAddWishList from "../../../helpers/handleAddWishList";

function PetListViewDesktop({ showInfo }) {
  const { currentPetList } = useSelector((state) => state.findPartner);
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.authSlice);

  return (
    <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
      {currentPetList.map((items) => (
        <PetCardsDesktop
          petType={items.petType}
          petBreed={items.petBreed}
          petGender={items.petGender}
          petLocation={items.petLocation}
          petName={items.petName}
          wishList={items.wishList}
          imgUrl={items.imgUrl}
          petYob={items.yob}
          showInfo={showInfo}
          HandleWishList={() => HandleAddWishList(isLoggedIn, navigate)}
        />
      ))}
    </div>
  );
}

export default PetListViewDesktop;
