import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function HandlePageNumber({
  currentPageNo,
  totalPagesNo,
  HandleChangePageNumber,
}) {
  return (
    <div className="flex justify-center py-5 items-center space-x-2">
      <p
        className={`bg-primary text-white text-md py-2 px-3 ${
          currentPageNo < 2 ? "disabled" : "cursor-pointer"
        }`}
        onClick={() => {
          if (currentPageNo > 1) HandleChangePageNumber(currentPageNo - 1);
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="pr-2" />
        Previous
      </p>
      <p
        className={`bg-primary cursor-pointer ${
          currentPageNo === 1 && "border border-black border-2"
        } text-white text-md py-2 px-3`}
        onClick={() => HandleChangePageNumber(1)}
      >
        1
      </p>
      {totalPagesNo > 1 && (
        <p
          className={`bg-primary ${
            currentPageNo === 2 && "border border-black border-2"
          } ${
            currentPageNo < 5 && "cursor-pointer"
          } text-white text-md py-2 px-3`}
          onClick={() => {
            if (currentPageNo < 5) HandleChangePageNumber(2);
          }}
        >
          {(currentPageNo > 4 && totalPagesNo > 7 && "...") || "2"}
        </p>
      )}
      {new Array(totalPagesNo).fill(null).map((items, index) => {
        if (
          (totalPagesNo <= 7 && index > 1) ||
          (index > 2 &&
            index < totalPagesNo - 1 &&
            index < currentPageNo + 3 &&
            index > currentPageNo - 3)
        ) {
          return (
            <p
              className={`bg-primary cursor-pointer ${
                currentPageNo === (totalPagesNo <= 7 ? index + 1 : index) &&
                "border border-black border-2"
              } text-white text-md py-2 px-3`}
              onClick={() =>
                HandleChangePageNumber(totalPagesNo <= 7 ? index + 1 : index)
              }
            >
              {totalPagesNo <= 7 ? index + 1 : index}
            </p>
          );
        }
      })}
      {totalPagesNo > 7 && (
        <>
          <p
            className={`bg-primary ${
              currentPageNo === totalPagesNo - 1 &&
              "border border-black border-2 "
            } ${
              currentPageNo > totalPagesNo - 5 && "cursor-pointer"
            } text-white text-md py-2 px-3`}
            onClick={() => {
              if (currentPageNo > totalPagesNo - 5)
                HandleChangePageNumber(totalPagesNo - 1);
            }}
          >
            {(currentPageNo < totalPagesNo - 4 && "...") || totalPagesNo - 1}
          </p>
          <p
            className={`bg-primary cursor-pointer ${
              currentPageNo === totalPagesNo && "border border-black border-2"
            } text-white text-md py-2 px-3`}
            onClick={() => HandleChangePageNumber(totalPagesNo)}
          >
            {totalPagesNo}
          </p>
        </>
      )}
      <p
        className={`bg-primary text-white text-md py-2 px-3 ${
          currentPageNo > totalPagesNo - 1 ? "disabled" : "cursor-pointer"
        }`}
        onClick={() => {
          if (currentPageNo < totalPagesNo)
            HandleChangePageNumber(currentPageNo + 1);
        }}
      >
        Next
        <FontAwesomeIcon icon={faChevronRight} className="pl-2" />
      </p>
    </div>
  );
}

export default HandlePageNumber;
