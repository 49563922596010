import { faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const PetDetailsPageDesktop = () => {
  const [PreviewImage, setPreviewImage] = useState(
    "https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:eco%2Cw_1200/MTc0MDk2MzYxNjM1OTgwODY2/what-you-should-know-about-owning-a-golden-retriever.jpg"
  );
  return (
    <div>
      <div className="flex items-start space-x-5">
        <div className="flex space-x-3">
          <div className="flex flex-col items-center justify-center space-y-5 overflow-y-scroll">
            <div
              className="w-[65px] h-[65px] cursor-pointer hover:border hover:border-black hover:p-2"
              onMouseEnter={() =>
                setPreviewImage(
                  "https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cq_auto:eco%2Cw_1200/MTk3MjI5OTczMDA0NjI0OTc5/cute-pet-names.png"
                )
              }
            >
              <img
                src="https://images.saymedia-content.com/.image/ar_1:1%2Cc_fill%2Ccs_srgb%2Cq_auto:eco%2Cw_1200/MTk3MjI5OTczMDA0NjI0OTc5/cute-pet-names.png"
                className="object-cover w-full h-full"
                alt=""
              />
            </div>
            <div
              className="w-[65px] h-[65px] cursor-pointer hover:border hover:border-black hover:p-2"
              onMouseEnter={() =>
                setPreviewImage(
                  "https://yourdost-blog-images.s3-ap-southeast-1.amazonaws.com/wp-content/uploads/2016/01/03165939/Dogs.jpg"
                )
              }
            >
              <img
                src="https://yourdost-blog-images.s3-ap-southeast-1.amazonaws.com/wp-content/uploads/2016/01/03165939/Dogs.jpg"
                className="object-cover w-full h-full"
                alt=""
              />
            </div>
            <div
              className="w-[65px] h-[65px] cursor-pointer hover:border hover:border-black hover:p-2"
              onMouseEnter={() =>
                setPreviewImage(
                  "https://static01.nyt.com/images/2020/05/09/multimedia/09sp-ai-pets-promo/09sp-ai-pets-promo-superJumbo.jpg"
                )
              }
            >
              <img
                src="https://static01.nyt.com/images/2020/05/09/multimedia/09sp-ai-pets-promo/09sp-ai-pets-promo-superJumbo.jpg"
                className="object-cover w-full h-full"
                alt=""
              />
            </div>
            <div
              className="w-[65px] h-[65px] cursor-pointer hover:border hover:border-black hover:p-2"
              onMouseEnter={() =>
                setPreviewImage(
                  "https://cdn.pixabay.com/photo/2014/11/30/14/11/cat-551554_640.jpg"
                )
              }
            >
              <img
                src="https://cdn.pixabay.com/photo/2014/11/30/14/11/cat-551554_640.jpg"
                className="object-cover w-full h-full"
                alt=""
              />
            </div>
            <div
              className="w-[65px] h-[65px] cursor-pointer hover:border hover:border-black hover:p-2"
              onMouseEnter={() =>
                setPreviewImage(
                  "https://images.pexels.com/photos/2318990/pexels-photo-2318990.jpeg?cs=srgb&dl=pexels-mithul-varshan-2318990.jpg&fm=jpg"
                )
              }
            >
              <img
                src="https://images.pexels.com/photos/2318990/pexels-photo-2318990.jpeg?cs=srgb&dl=pexels-mithul-varshan-2318990.jpg&fm=jpg"
                className="object-cover w-full h-full"
                alt=""
              />
            </div>
          </div>
          <div className="h-[460px] w-[480px] ">
            <img
              src={PreviewImage}
              className="object-cover w-full h-full"
              alt=""
            />
          </div>
        </div>
        <div className="">
          <p className="text-md text-gray-500 text-left font-bold uppercase">
            dog
          </p>
          <p className="text-black text-2xl font-bold">
            Buddy - Golden Retriever - Male
          </p>
          <table className="w-full my-6">
            <tbody>
              <tr className="text-lg font-bold">
                <td className="text-gray-500 py-3">Height</td>
                <td className="text-black py-3">250 cm</td>
              </tr>
              <tr className="text-lg font-bold">
                <td className="text-gray-500 py-3">Weight</td>
                <td className="text-black py-3">60 kg</td>
              </tr>
              <tr className="text-lg font-bold">
                <td className="text-gray-500 py-3">Age</td>
                <td className="text-black py-3">10 yrs</td>
              </tr>
              <tr className="text-lg font-bold">
                <td className="text-gray-500 py-3">Color</td>
                <td className="text-black py-3">Brown</td>
              </tr>
              <tr className="text-lg font-bold">
                <td className="text-gray-500 py-3">Gender</td>
                <td className="text-black py-3">Male</td>
              </tr>
              <tr className="text-lg font-bold">
                <td className="text-gray-500 py-3">City</td>
                <td className="text-black py-3">Chennai</td>
              </tr>
            </tbody>
          </table>

          {/* <div>
            <span className="text-white px-5 font-bold py-1 rounded bg-blue-600">
              <FontAwesomeIcon icon={faCheck} className="pr-3" />
              Certified
            </span>
          </div> */}
          <div className=" text-left space-x-5">
            <button className="bg-gray-600 text-white rounded-3xl h-10 w-10">
              <FontAwesomeIcon icon={faHeart} />
            </button>
            <button className="bg-blue-600 text-white rounded-3xl h-10 w-10">
              <FontAwesomeIcon icon={faShare} />
            </button>
          </div>
        </div>
      </div>

      <div className="my-9">
        <h1 className="text-black font-black text-2xl">
          What people says about owner
        </h1>
      </div>
    </div>
  );
};

export default PetDetailsPageDesktop;
