// SideMenu.js

import React from "react";
import "./sidemenu.css";

const SideMenu = ({ isOpen, divClassName, children, width, sideMenuClass }) => {
  return (
    <div
      className={`side-menu ${sideMenuClass}`}
      style={{ left: `${isOpen ? "0" : `-${width}`}`, width }}
    >
      <div className={`${divClassName} menu-content`}>{children}</div>
    </div>
  );
};

export default SideMenu;
