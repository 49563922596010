import { createSlice } from "@reduxjs/toolkit";

let initialValue = {
  petInfo: {
    petType: "",
    petBreed: "",
  },
  currentPetList: [
    {
      petName: "Max",
      petType: "Dog",
      petBreed: "Labrador",
      petLocation: "City A",
      petGender: "Male",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://hips.hearstapps.com/hmg-prod/images/small-dog-breeds-659dd5cce053b.jpeg?crop=0.669xw:1.00xh;0.166xw,0&resize=640:*",
    },
    {
      petName: "Luna",
      petType: "Cat",
      petBreed: "Persian",
      petLocation: "City B",
      petGender: "Female",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://www.catbreedslist.com/stories-images/what-kind-of-cat-breed-is-beluga-domestic-shorthair.jpg",
    },
    {
      petName: "Charlie",
      petType: "Dog",
      petBreed: "Poodle",
      petLocation: "City C",
      petGender: "Male",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://www.rover.com/blog/wp-content/uploads/food-allergies-dogs-feeding-dog.jpg",
    },
    {
      petName: "Misty",
      petType: "Cat",
      petBreed: "Siamese",
      petLocation: "City D",
      petGender: "Female",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://images.pexels.com/photos/2071882/pexels-photo-2071882.jpeg?cs=srgb&dl=pexels-wojciech-kumpicki-2071882.jpg&fm=jpg",
    },
    {
      petName: "Rocky",
      petType: "Dog",
      petBreed: "German Shepherd",
      petLocation: "City E",
      petGender: "Male",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://hips.hearstapps.com/hmg-prod/images/small-dog-breeds-659dd5cce053b.jpeg?crop=0.669xw:1.00xh;0.166xw,0&resize=640:*",
    },
    {
      petName: "Daisy",
      petType: "Cat",
      petBreed: "Maine Coon",
      petLocation: "City F",
      petGender: "Female",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://thumbs.dreamstime.com/b/close-up-portrait-ginger-cat-white-background-isolated-105770790.jpg",
    },
    {
      petName: "Buddy",
      petType: "Dog",
      petBreed: "Golden Retriever",
      petLocation: "City G",
      petGender: "Male",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://ghk.h-cdn.co/assets/18/01/1280x640/landscape-1515101826-boerboel.jpg",
    },
    {
      petName: "Milo",
      petType: "Cat",
      petBreed: "Bengal",
      petLocation: "City H",
      petGender: "Male",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://thumbs.dreamstime.com/b/close-up-portrait-ginger-cat-white-background-isolated-105770790.jpg",
    },
    {
      petName: "Sophie",
      petType: "Dog",
      petBreed: "Beagle",
      petLocation: "City I",
      petGender: "Female",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://www.rover.com/blog/wp-content/uploads/food-allergies-dogs-feeding-dog.jpg",
    },
    {
      petName: "Shadow",
      petType: "Cat",
      petBreed: "Ragdoll",
      petLocation: "City J",
      petGender: "Male",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://images.pexels.com/photos/2071882/pexels-photo-2071882.jpeg?cs=srgb&dl=pexels-wojciech-kumpicki-2071882.jpg&fm=jpg",
    },
    {
      petName: "Bailey",
      petType: "Dog",
      petBreed: "Dachshund",
      petLocation: "City K",
      petGender: "Female",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://ghk.h-cdn.co/assets/18/01/1280x640/landscape-1515101826-boerboel.jpg",
    },
    {
      petName: "Oscar",
      petType: "Cat",
      petBreed: "Sphynx",
      petLocation: "City L",
      petGender: "Male",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://www.fearfreehappyhomes.com/wp-content/uploads/2021/10/Kim-2-747x1024.jpg",
    },
    {
      petName: "Coco",
      petType: "Dog",
      petBreed: "Shih Tzu",
      petLocation: "City M",
      petGender: "Female",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://www.rover.com/blog/wp-content/uploads/food-allergies-dogs-feeding-dog.jpg",
    },
    {
      petName: "Leo",
      petType: "Cat",
      petBreed: "Scottish Fold",
      petLocation: "City N",
      petGender: "Male",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://thumbs.dreamstime.com/b/close-up-portrait-ginger-cat-white-background-isolated-105770790.jpg",
    },
    {
      petName: "Ruby",
      petType: "Dog",
      petBreed: "Boxer",
      petLocation: "City O",
      petGender: "Female",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://cxarchive.gseis.ucla.edu/partnerships-grants/tiip/tiip-2-workspaces/farmdale-workspace/libraries/photo-library/bunny%20CD/image",
    },
    {
      petName: "Simba",
      petType: "Cat",
      petBreed: "British Shorthair",
      petLocation: "City P",
      petGender: "Male",
      wishList: false,
      yob: "2019",
      imgUrl:
        "https://www.fearfreehappyhomes.com/wp-content/uploads/2021/10/Kim-2-747x1024.jpg",
    },
    {
      petName: "Cooper",
      petType: "Dog",
      petBreed: "Rottweiler",
      petLocation: "City Q",
      petGender: "Male",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2017/11/06154034/Akita-standing-outdoors-in-the-summer.jpg",
    },
    {
      petName: "Zoe",
      petType: "Cat",
      petBreed: "Norwegian Forest",
      petLocation: "City R",
      petGender: "Female",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://www.kapruka.com/shops/specialGifts/productImages/16847541856392_ginger_main.jpg",
    },
    {
      petName: "Bella",
      petType: "Rabbit",
      petBreed: "Dutch",
      petLocation: "City S",
      petGender: "Female",
      wishList: true,
      yob: "2014",
      imgUrl:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Oryctolagus_cuniculus_Rcdo.jpg/640px-Oryctolagus_cuniculus_Rcdo.jpg",
    },
    {
      petName: "Oliver",
      petType: "Rabbit",
      petBreed: "Lop Eared",
      petLocation: "City T",
      petGender: "Male",
      wishList: false,
      yob: "2013",
      imgUrl:
        "https://cxarchive.gseis.ucla.edu/partnerships-grants/tiip/tiip-2-workspaces/farmdale-workspace/libraries/photo-library/bunny%20CD/image",
    },
    {
      petName: "Molly",
      petType: "Dog",
      petBreed: "Cavalier King Charles",
      petLocation: "City U",
      petGender: "Female",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://hips.hearstapps.com/hmg-prod/images/small-dog-breeds-659dd5cce053b.jpeg?crop=0.669xw:1.00xh;0.166xw,0&resize=640:*",
    },
    {
      petName: "Nala",
      petType: "Cat",
      petBreed: "Siamese",
      petLocation: "City V",
      petGender: "Female",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://www.rocklinranchvet.com/blog/wp-content/uploads/2014/08/iStock_000015945987_Medium.jpg",
    },
    {
      petName: "Teddy",
      petType: "Dog",
      petBreed: "Shih Tzu",
      petLocation: "City W",
      petGender: "Male",
      wishList: true,
      yob: "2015",
      imgUrl:
        "https://media.cnn.com/api/v1/images/stellar/prod/201030094143-stock-rhodesian-ridgeback.jpg?q=w_2187,h_1458,x_0,y_0,c_fill",
    },
    {
      petName: "Chloe",
      petType: "Cat",
      petBreed: "Persian",
      petLocation: "City X",
      petGender: "Female",
      wishList: false,
      yob: "2018",
      imgUrl:
        "https://www.kapruka.com/shops/specialGifts/productImages/16847541856392_ginger_main.jpg",
    },
    {
      petName: "Zeus",
      petType: "Dog",
      petBreed: "Siberian Husky",
      petLocation: "City Y",
      petGender: "Male",
      wishList: true,
      yob: "2018",
      imgUrl:
        "https://s3.amazonaws.com/cdn-origin-etr.akc.org/wp-content/uploads/2017/11/06154034/Akita-standing-outdoors-in-the-summer.jpg",
    },
    // {
    //   petName: "Lily",
    //   petType: "Cat",
    //   petBreed: "Maine Coon",
    //   petLocation: "City Z",
    //   petGender: "Female",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://www.rocklinranchvet.com/blog/wp-content/uploads/2014/08/iStock_000015945987_Medium.jpg",
    // },
    // {
    //   petName: "Copper",
    //   petType: "Dog",
    //   petBreed: "Bulldog",
    //   petLocation: "City A1",
    //   petGender: "Male",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://media.cnn.com/api/v1/images/stellar/prod/201030094143-stock-rhodesian-ridgeback.jpg?q=w_2187,h_1458,x_0,y_0,c_fill",
    // },
    // {
    //   petName: "Whiskers",
    //   petType: "Cat",
    //   petBreed: "Ragdoll",
    //   petLocation: "City B1",
    //   petGender: "Male",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://wallpapers.com/images/hd/cat-pictures-9myur9v0ca86msmx.jpg",
    // },
    // {
    //   petName: "Mocha",
    //   petType: "Rabbit",
    //   petBreed: "Mini Rex",
    //   petLocation: "City C1",
    //   petGender: "Female",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Oryctolagus_cuniculus_Rcdo.jpg/640px-Oryctolagus_cuniculus_Rcdo.jpg",
    // },
    // {
    //   petName: "Ollie",
    //   petType: "Rabbit",
    //   petBreed: "Holland Lop",
    //   petLocation: "City D1",
    //   petGender: "Male",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://abandonedrabbits.com/wp-content/uploads/2021/12/pablo-martinez-eXLCx0XBaUE-unsplash-1-scaled.jpg",
    // },
    // {
    //   petName: "Winston",
    //   petType: "Dog",
    //   petBreed: "Great Dane",
    //   petLocation: "City E1",
    //   petGender: "Male",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://i.natgeofe.com/n/187b3223-0b45-4aa5-ae5c-24793dd2d6cb/01-german-shepherd-coronavirus-bwtkdt.jpg",
    // },
    // {
    //   petName: "Sasha",
    //   petType: "Cat",
    //   petBreed: "Scottish Fold",
    //   petLocation: "City F1",
    //   petGender: "Female",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://www.kapruka.com/shops/specialGifts/productImages/16847541856392_ginger_main.jpg",
    // },
    // {
    //   petName: "Ace",
    //   petType: "Dog",
    //   petBreed: "Doberman",
    //   petLocation: "City G1",
    //   petGender: "Male",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://i.natgeofe.com/n/187b3223-0b45-4aa5-ae5c-24793dd2d6cb/01-german-shepherd-coronavirus-bwtkdt.jpg",
    // },
    // {
    //   petName: "Mittens",
    //   petType: "Cat",
    //   petBreed: "Siberian",
    //   petLocation: "City H1",
    //   petGender: "Female",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://wallpapers.com/images/hd/cat-pictures-9myur9v0ca86msmx.jpg",
    // },
    // {
    //   petName: "Bentley",
    //   petType: "Dog",
    //   petBreed: "Corgi",
    //   petLocation: "City I1",
    //   petGender: "Male",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://hips.hearstapps.com/hmg-prod/images/afghan-hounds-64196d0ab7060.jpg?crop=0.516xw:1.00xh;0.269xw,0&resize=640:*",
    // },
    // {
    //   petName: "Gizmo",
    //   petType: "Cat",
    //   petBreed: "Bengal",
    //   petLocation: "City J1",
    //   petGender: "Male",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://www.catbreedslist.com/stories-images/what-kind-of-cat-breed-is-beluga-domestic-shorthair.jpg",
    // },
    // {
    //   petName: "Rosie",
    //   petType: "Dog",
    //   petBreed: "Golden Retriever",
    //   petLocation: "City K1",
    //   petGender: "Female",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://i.natgeofe.com/n/187b3223-0b45-4aa5-ae5c-24793dd2d6cb/01-german-shepherd-coronavirus-bwtkdt.jpg",
    // },
    // {
    //   petName: "Jasper",
    //   petType: "Cat",
    //   petBreed: "Persian",
    //   petLocation: "City L1",
    //   petGender: "Male",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://qph.cf2.quoracdn.net/main-qimg-fb45454aa6846608b109b70680d10439-lq",
    // },
    // {
    //   petName: "Lola",
    //   petType: "Rabbit",
    //   petBreed: "Netherland Dwarf",
    //   petLocation: "City M1",
    //   petGender: "Female",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://abandonedrabbits.com/wp-content/uploads/2021/12/pablo-martinez-eXLCx0XBaUE-unsplash-1-scaled.jpg",
    // },
    // {
    //   petName: "Harley",
    //   petType: "Rabbit",
    //   petBreed: "Mini Lop",
    //   petLocation: "City N1",
    //   petGender: "Male",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://preview.redd.it/yl5cuc50qdg91.jpg?width=640&crop=smart&auto=webp&s=e91032bd8475d53c37d3ee2cfcf3b528d0a0bea6",
    // },
    // {
    //   petName: "Bear",
    //   petType: "Dog",
    //   petBreed: "Bernese Mountain Dog",
    //   petLocation: "City O1",
    //   petGender: "Male",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://upload.wikimedia.org/wikipedia/commons/c/c0/Mongrel_1.jpg",
    // },
    // {
    //   petName: "Mocha",
    //   petType: "Cat",
    //   petBreed: "Ragdoll",
    //   petLocation: "City P1",
    //   petGender: "Female",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://www.usatoday.com/gcdn/presto/2019/03/10/USAT/74e50091-011d-4c89-bb10-ef29e4f5a202-XXX_Reggie_the_cat_20190222_USA_djm_0022.jpg?crop=3556,2667,x127,y0",
    // },
    // {
    //   petName: "Zeppelin",
    //   petType: "Dog",
    //   petBreed: "Siberian Husky",
    //   petLocation: "City Q1",
    //   petGender: "Male",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://hips.hearstapps.com/hmg-prod/images/afghan-hounds-64196d0ab7060.jpg?crop=0.516xw:1.00xh;0.269xw,0&resize=640:*",
    // },
    // {
    //   petName: "Salem",
    //   petType: "Cat",
    //   petBreed: "Maine Coon",
    //   petLocation: "City R1",
    //   petGender: "Male",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://qph.cf2.quoracdn.net/main-qimg-fb45454aa6846608b109b70680d10439-lq",
    // },
    // {
    //   petName: "Nova",
    //   petType: "Dog",
    //   petBreed: "Australian Shepherd",
    //   petLocation: "City S1",
    //   petGender: "Female",
    //   wishList: true,
    //   yob: "2018",
    //   imgUrl:
    //     "https://upload.wikimedia.org/wikipedia/commons/c/c0/Mongrel_1.jpg",
    // },
    // {
    //   petName: "Cleo",
    //   petType: "Cat",
    //   petBreed: "Siamese",
    //   petLocation: "City T1",
    //   petGender: "Female",
    //   wishList: false,
    //   yob: "2018",
    //   imgUrl:
    //     "https://www.usatoday.com/gcdn/presto/2019/03/10/USAT/74e50091-011d-4c89-bb10-ef29e4f5a202-XXX_Reggie_the_cat_20190222_USA_djm_0022.jpg?crop=3556,2667,x127,y0",
    // },
  ],
  totalCount: 0,
  totalPagesNo: 0,
  currentPageNo: 0,
};
const PetPartnerListStore = createSlice({
  name: "petPartnerListStore",
  initialState: initialValue,
  reducers: {
    setCurrentPetList: (state, actions) => {
      state.currentPetList = actions.payload;
    },
    setTotalCount: (state, actions) => {
      state.totalCount = actions.payload;
    },
    setTotalPageNo: (state, actions) => {
      state.totalPagesNo = actions.payload;
    },
    setCurrentPageNo: (state, actions) => {
      state.currentPageNo = actions.payload;
    },
    setPetInfo: (state, actions) => {
      state.petInfo = actions.payload;
    },
  },
});

export const {
  setCurrentPetList,
  setTotalCount,
  setCurrentPageNo,
  setTotalPageNo,
  setPetInfo,
} = PetPartnerListStore.actions;
export default PetPartnerListStore.reducer;
