import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faAngleDown,
  faSearch,
} from "@fortawesome/free-solid-svg-icons"; // Assuming you have imported the required icons

const MultiselectDropdown = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const getSelectedItemsLabel = () => {
    if (selectedOptions.length === 0) return "Select Options";
    return `${selectedOptions.length} selected`;
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectAllOptions = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
    } else {
      const allOptionValues = options.map((option) => option.value);
      setSelectedOptions(allOptionValues);
    }
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <span className="rounded-md shadow-sm" ref={dropdownRef}>
          <button
            type="button"
            className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none focus:border-blue-500"
            onClick={toggleDropdown}
          >
            {getSelectedItemsLabel()}
            <FontAwesomeIcon icon={faAngleDown} className="ml-2" />
          </button>
        </span>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 max-h-[35vh] overflow-y-auto">
          <div className="py-1 px-3">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="w-full border rounded-md pl-8 py-2 focus:outline-none focus:border-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute top-3 left-3 text-gray-400"
              />
            </div>
          </div>
          <div className="py-1">
            <div
              className="flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={selectAllOptions}
            >
              <span>
                {selectedOptions.length === options.length
                  ? "Unselect All"
                  : "Select All"}
              </span>
            </div>
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                className="flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleOptionClick(option.value)}
              >
                <span>{option.label}</span>
                {selectedOptions.includes(option.value) ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : null}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiselectDropdown;
