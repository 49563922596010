import { createSlice } from "@reduxjs/toolkit";

let InitialValue = {
  petType: "",
  petBreed: "",
  petSex: "",
  petAge: "",
  verificationState: "",
  preferredLocation: "",
  sortByList: [
    {
      label: "Popularity",
      value: "popular",
    },
    {
      label: "Age",
      value: "age",
    },
    {
      label: "Newest First",
      value: "new",
    },
    {
      label: "Certified First",
      value: "certified",
    },
  ],
  sortBy: "",
};
const PetPartnerListFilters = createSlice({
  name: "PetPartnerListFilters",
  initialState: InitialValue,
  reducers: {
    setFilters: (state, actions) => {
      state[actions.payload.key] = actions.payload.value;
    },
    setSortBy: (state, actions) => {
      state.sortBy = actions.payload;
    },
  },
});

export const { setFilters, setSortBy } = PetPartnerListFilters.actions;
export default PetPartnerListFilters.reducer;
