import React from "react";
import { Link } from "react-router-dom";

function DashboardSidenavDesktop() {
  return (
    <div>
      <div className="flex flex-col p-5">
        <Link
          to={"/member/dashboard/messages"}
          className="text-white font-bold text-xl my-3"
        >
          Messages
        </Link>
        <Link
          to={"/member/dashboard/managepets"}
          className="text-white font-bold text-xl my-3"
        >
          Add new pet
        </Link>
        <Link
          to={"/member/dashboard/managepets"}
          className="text-white font-bold text-xl my-3"
        >
          Manage your pets
        </Link>
        <Link
          to={"/member/dashboard/favourite"}
          className="text-white font-bold text-xl my-3"
        >
          Favourites
        </Link>
        <Link
          to={"/member/dashboard/report"}
          className="text-white font-bold text-xl my-3"
        >
          Report
        </Link>
      </div>
    </div>
  );
}

export default DashboardSidenavDesktop;
