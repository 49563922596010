import { Route, Routes } from "react-router-dom";
import DesktopWelcome from "./welcome";
import FindPetPartnerDeskTop from "./findPetPartner";
import PetListWithFiltersDesktop from "./findPetPartner/petListWithFilters";
import DashboardDesktop from "./dashboard";
import { DashboardManagePetsDesktop } from "./dashboard/content";
import DashboardMessagesDesktop from "./dashboard/content/messages";
import DashboardReportDesktop from "./dashboard/content/report";
import DashboardFavouritesDesktop from "./dashboard/content/favourites";
import AddNewPetDeskTop from "./addPet";
import LoginPage from "./signin";
import AdoptPetDeskTop from "./buyAdoptpet";
import AboptPetListWithFiltersDesktop from "./buyAdoptpet/petListWithFilters";
import AddPetCompletedPage from "./addPet/completedPage";
import AuthRoute from "./authNavigator";
import { useDispatch } from "react-redux";
import { useContext, useEffect } from "react";
import { getItemsFromLocalStorage } from "../../helpers/handleLocalStorage";
import { setIsLoggedIn } from "../../store/authStore";
import PreloaderContext from "../../context/preloaderContext";
import SignupNewUser from "./signup";

const DesktopNavigator = () => {
  const { setShowPreloader } = useContext(PreloaderContext);
  const dispatch = useDispatch();
  useEffect(() => {
    setShowPreloader(true);
    const getToken = getItemsFromLocalStorage("AuthToken");
    setShowPreloader(false);
    if (!getToken) {
      dispatch(setIsLoggedIn(false));
    }
  }, []);
  return (
    <Routes>
      <Route path="/" Component={DesktopWelcome} />
      <Route path="/login" Component={LoginPage} />
      <Route path="/register" Component={SignupNewUser} />
      <Route path="/find-parnter">
        <Route path="" Component={FindPetPartnerDeskTop} />
        <Route
          path="pet-list/:type/:breed/:pageNumber/:petId?"
          Component={PetListWithFiltersDesktop}
        />
      </Route>
      <Route path="/add-pet/">
        <Route
          path=":step"
          element={<AuthRoute Component={AddNewPetDeskTop} />}
        />
        <Route
          path="thankyou"
          element={<AuthRoute Component={AddPetCompletedPage} />}
        />
      </Route>
      <Route path="/adopt-pet">
        <Route path="" Component={AdoptPetDeskTop} />
        <Route path="pet-list" Component={AboptPetListWithFiltersDesktop} />
      </Route>
      <Route path="/member">
        <Route
          path="dashboard"
          element={<AuthRoute Component={DashboardDesktop} />}
        >
          <Route
            path="managepets"
            element={<AuthRoute Component={DashboardManagePetsDesktop} />}
          />
          <Route
            path="messages"
            element={<AuthRoute Component={DashboardMessagesDesktop} />}
          />
          <Route
            path="report"
            element={<AuthRoute Component={DashboardReportDesktop} />}
          />
          <Route
            path="favourite"
            element={<AuthRoute Component={DashboardFavouritesDesktop} />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default DesktopNavigator;
