import React, { useEffect, useState } from "react";
import CustomSelect from "../../../components/customSelect";
import { getItemsFromLocalStorage } from "../../../helpers/handleLocalStorage";
import CustomRadioBtn from "../../../components/customRadio";
import CustomMultiSelectDropdown from "../../../components/customMultiSelect";
import CustomCheckbox from "../../../components/customCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faRotateRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function PetListFiltersDesktop({ toggleFilterBar, filterBarState }) {
  const [defaultFilters, setDefaultFilters] = useState({});
  const [petType, setPetType] = useState([
    {
      label: "Dog",
      value: "dog",
    },
    {
      label: "Cat",
      value: "cat",
    },
    {
      label: "Dove",
      value: "dove",
    },
  ]);

  const [breedType, setBreedType] = useState([
    {
      label: "Puppy",
      value: "puppy",
    },
    {
      label: "Puppy1",
      value: "puppy1",
    },
    {
      label: "Puppy2",
      value: "puppy2",
    },
  ]);

  const [petsex, setPetsex] = useState([
    {
      label: "Any",
      value: "any",
    },
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    },
  ]);

  const [petAge, setPetAge] = useState([
    {
      label: "Any age",
      value: "any",
    },
    {
      label: "Baby",
      value: "baby",
    },
    {
      label: "Young",
      value: "young",
    },
  ]);

  const [locationList, setLocationList] = useState([
    { label: "Chennai", value: "chennai" },
    { label: "Mumbai", value: "mumbai" },
    { label: "Coimbatore", value: "coimbatore" },
    { label: "Trichy", value: "trichy" },
    { label: "Madurai", value: "madurai" },
    { label: "Bangalore", value: "bangalore" },
  ]);

  const [verificationStatusList, setVerificationStatusList] = useState([
    {
      label: "Verified pet",
      value: "verified",
    },
    {
      label: "Certified pet",
      value: "certified",
    },
  ]);

  useEffect(() => {
    const searchPartnerInfo = getItemsFromLocalStorage("searchPetPartner");
    setDefaultFilters(JSON.parse(searchPartnerInfo));
  }, []);

  const HandlePetTypeInfoChange = (key, value) => {
    console.log("change value", key, value);
  };

  const HandleApplyFilter = () => {
    toggleFilterBar(!filterBarState);
  };

  const HandleResetFilter = () => {};
  return (
    <div className="p-5 space-y-4">
      <div
        className="absolute right-5 top-5 font-bold text-white text-xl cursor-pointer"
        onClick={() => toggleFilterBar(!filterBarState)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
      <div>
        <p className="text-light text-lg font-bold">Your looking for: </p>
        <div className="flex flex-row my-4 justify-evenly">
          <CustomSelect
            label={"Pet type"}
            labelClass={"text-light text-md px-3 "}
            options={petType}
            selectClass={"text-dark px-4 py-1 rounded-md"}
            defaultValue={defaultFilters?.petInfo?.type}
            onChange={(e) => HandlePetTypeInfoChange("type", e.target.value)}
          />
          <CustomSelect
            label={"Pet breed"}
            labelClass={"text-light text-md px-3 "}
            options={breedType}
            selectClass={"text-dark px-4 py-1 rounded-md"}
            defaultValue={defaultFilters?.petInfo?.breed}
            onChange={(e) => HandlePetTypeInfoChange("breed", e.target.value)}
          />
        </div>
      </div>
      <div>
        <p className="text-light text-lg font-bold">Pet's sex: </p>
        <CustomRadioBtn
          name={"petSexOnList"}
          options={petsex}
          labelClass={"text-light text-md mx-2"}
          defaultValue={"any"}
          radioClass={
            "form-radio h-5 cursor-pointer w-5 mr-2 focus:ring-indigo-500"
          }
          SelectlabelClass={"text-white"}
          outerDivClass={"flex flex-row flex-wrap justify-evenly my-4 "}
          onChange={(value) => console.log("checked values", value)}
        />
      </div>
      <div>
        <p className="text-light text-lg font-bold">Pet's Age: </p>
        <CustomRadioBtn
          name={"petAgeOnList"}
          options={petAge}
          labelClass={"text-white text-md mx-2"}
          defaultValue={"any"}
          radioClass={
            "text-white form-radio h-5 cursor-pointer w-5 mr-2 focus:ring-indigo-500"
          }
          SelectlabelClass={"text-white"}
          outerDivClass={"flex flex-row flex-wrap justify-evenly my-4 "}
          onChange={(value) => console.log("checked values", value)}
        />
      </div>
      <div>
        <p className="text-light text-lg font-bold">Show only: </p>
        <CustomCheckbox
          options={verificationStatusList}
          labelClass={"text-light text-lg mx-2"}
          checkboxClass={"form-radio h-5 cursor-pointer w-5 mr-2"}
          onChange={(value) => console.log("checked values", value)}
          outerDivClass={"flex flex-row flex-wrap justify-evenly my-4 "}
        />
      </div>
      <div>
        <p className="text-light text-lg font-bold">Preferred locations: </p>
        <div className="flex flex-col space-y-2 py-4">
          <CustomMultiSelectDropdown
            options={locationList}
            setSelectedItems={(value) =>
              console.log("selected countries", value)
            }
          />
        </div>
      </div>
      <div className="text-center space-x-8">
        <button
          className="bg-primary font-bold text-lg text-ternary  px-3 py-2 "
          onClick={() => HandleApplyFilter()}
        >
          <FontAwesomeIcon icon={faFilter} /> Apply Filters
        </button>
        <button
          className="bg-red-700 font-bold text-lg text-ternary  px-3 py-2 "
          onClick={() => HandleResetFilter()}
        >
          <FontAwesomeIcon icon={faRotateRight} /> Reset
        </button>
      </div>
    </div>
  );
}

export default PetListFiltersDesktop;
