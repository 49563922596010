const CustomSelect = ({
  label,
  options,
  defaultValue,
  labelClass,
  selectClass,
  optionClass,
  outerdivClass,
  onChange,
  defaultLabel,
  required,
  hint,
}) => {
  return (
    <div className={outerdivClass}>
      <label className={labelClass}>
        {label}
        {required && <span className="text-md text-red-600 font-bold">*</span>}
      </label>
      <div>
        <select
          defaultValue={defaultValue}
          className={`  pl-3 ${selectClass}`}
          onChange={(e) => onChange(e.target.value)}
          required={required}
        >
          {options && options.length === 0 && (
            <option className={optionClass} disabled={true}>
              No Items
            </option>
          )}
          {(!defaultValue || defaultValue === "") && (
            <option
              className={optionClass}
              value={""}
              selected={true}
              disabled={true}
            >
              {defaultLabel || "Select option"}
            </option>
          )}

          {options &&
            options.map((items, id) => (
              <option
                className={optionClass}
                key={id}
                value={items.value}
                selected={
                  defaultValue &&
                  defaultValue !== "" &&
                  defaultValue === items.value
                }
              >
                {items.label}
              </option>
            ))}
        </select>
        {hint ? <span className="text-sm text-black pl-2">{hint}</span> : <></>}
      </div>
    </div>
  );
};

export default CustomSelect;
