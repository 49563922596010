import React from "react";
import DashboardSidenavDesktop from "./sidenav";
import { Outlet } from "react-router-dom";

function DashboardDesktop() {
  return (
    <div>
      <div className="flex">
        <div className="w-1/6 h-[100vh] bg-secondary">
          <DashboardSidenavDesktop />
        </div>
        <div className="w-5/6 bg-ternary">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default DashboardDesktop;
