import React, { useEffect, useState } from "react";

function ImageSelectBox({
  options,
  defaultValue,
  height,
  width,
  outerDivClass,
  masterDivCLass,
  isSingleSelect,
  ...props
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const HandleOnSelect = (value) => {
    if(isSingleSelect && !selectedItems.includes(value)){
      setSelectedItems([value])
    }
    else if (selectedItems.includes(value)) {
      let tempSelected = JSON.parse(JSON.stringify(selectedItems));
      tempSelected.splice(
        tempSelected.findIndex((item) => item === value),
        1
      );
      setSelectedItems(tempSelected);
    } else {
      setSelectedItems([...selectedItems, value]);
    }
  };

  useEffect(() => {
    props.onSelect(selectedItems);
  }, [selectedItems]);

  return (
    <div className={masterDivCLass}>
      {options?.map((items) => (
        <div
          style={{ height, width, position: "relative", cursor: "pointer" }}
          className={`${
            (defaultValue &&
              defaultValue.length > 0 &&
              defaultValue.includes(items.value)) ||
            selectedItems.includes(items.value)
              ? "bg-[#0047ab]"
              : "bg-white"
          } ${outerDivClass}`}
          onClick={() => HandleOnSelect(items.value)}
        >
          <img
            src={items.label}
            alt="logo"
            className="h-full w-full object-cover"
          />
        </div>
      ))}
    </div>
  );
}

export default ImageSelectBox;
