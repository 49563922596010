import React, { useState } from "react";

const CustomInput = ({
  label,
  type,
  placeholder,
  onChange,
  name,
  className,
  labelClass,
  outerDivClass,
  required,
  inputClassName,
  suffix,
  suffixClassName,
  prefix,
  prefixClassName,
  defaultValue,
  hint,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue || "");

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
  };

  return (
    <div className={`custom-input ${outerDivClass}`}>
      <label className={labelClass}>
        {label}{" "}
        {required && <span className="text-md text-red-600 font-bold">*</span>}
      </label>
      <div>
        <div className={`flex ${className}`}>
          {prefix && (
            <span
              className={` flex items-center px-2 text-gray-500 font-bold ${prefixClassName}`}
            >
              {prefix}
            </span>
          )}
          <input
            type={type}
            value={value}
            name={name}
            className={`w-full pl-3  focus:outline-none focus:ring  ${inputClassName}`}
            onChange={handleChange}
            placeholder={placeholder}
            required={required}
            {...props}
          />
          {suffix && (
            <span
              className={` flex items-center px-2 text-gray-500 font-bold ${suffixClassName}`}
            >
              {suffix}
            </span>
          )}
        </div>
        {hint ? (
          <span className="text-sm text-gray-600 font-bold pl-1">{hint}</span>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
