import { useState } from "react";
import CustomCheckbox from "../../components/customCheckbox";
import CustomInput from "../../components/customInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
const googleIcon = require("../../assets/images/png/google-icon.png");
const callIcon = require("../../assets/images/png/call-img.png");
const SignupNewUser = () => {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    mobile: "",
    dob: "",
    plotno: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    hadshop: 0,
    sameaspersonaladdress: 0,
    shopname: "",
    shopaddress: "",
    shopcity: "",
    shopstate: "",
    shoppincode: "",
    shopcontact: "",
  });

  const HandleInput = (key, value) => {
    setUserInfo({ ...userInfo, [key]: value });
  };

  const personalAddressandShopAddresssame = (value) => {
    if (!value) {
      setUserInfo({
        ...userInfo,
        sameaspersonaladdress: 0,
        shopaddress: "",
        shopcity: "",
        shopstate: "",
        shoppincode: "",
        shopcontact: "",
      });
    } else {
      setUserInfo({
        ...userInfo,
        sameaspersonaladdress: 1,
        shopaddress: userInfo.address,
        shopcity: userInfo.city,
        shopstate: userInfo.state,
        shoppincode: userInfo.pincode,
        shopcontact: userInfo.mobile,
      });
    }
  };
  return (
    <div>
      {console.log("user", userInfo)}
      <div className="bg-ternary flex justify-center items-center fixed top-0 left-0 right-0 bottom-0 overflow-auto">
        <form className="my-8">
          <h1 className="text-center font-bold text-black text-4xl ">
            User Registration{" "}
          </h1>
          <div className="space-y-5 mt-5  h-[65vh] bg-ternary border p-4 overflow-y-scroll">
            <table className="user-form-section">
              <tbody>
                <tr>
                  <th colspan="3" className="text-left">
                    <h1 className="text-secondary font-bold text-2xl">
                      Personal Information
                    </h1>
                  </th>
                </tr>
                <tr>
                  <td>
                    <CustomInput
                      label={"Name"}
                      type={"text"}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      defaultValue={userInfo.name}
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"name"}
                      placeholder={"Username"}
                      onChange={(value) => HandleInput("name", value)}
                    />
                  </td>
                  <td>
                    <CustomInput
                      label={"Email"}
                      type={"email"}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      defaultValue={userInfo.email}
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"email"}
                      placeholder={"Email address"}
                      onChange={(value) => HandleInput("email", value)}
                    />
                  </td>
                  <td>
                    <CustomInput
                      label={"Mobile"}
                      type={"text"}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      defaultValue={userInfo.mobile}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"name"}
                      placeholder={"Mobile Number"}
                      onChange={(value) => HandleInput("mobile", value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomInput
                      label={"Date of birth"}
                      type={"text"}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      defaultValue={userInfo.dob}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"dob"}
                      placeholder={"DD/MM/YYYY"}
                      onChange={(value) => HandleInput("dob", value)}
                    />
                  </td>
                </tr>
                <tr>
                  <th colspan="3" className="text-left">
                    <h1 className="text-secondary font-bold text-2xl">
                      Address Information
                    </h1>
                  </th>
                </tr>
                <tr>
                  <td>
                    <CustomInput
                      label={"Plot no"}
                      type={"text"}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      defaultValue={userInfo.plotno}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"plotno"}
                      placeholder={"Plot / Door no"}
                      onChange={(value) => HandleInput("plotno", value)}
                    />
                  </td>
                  <td>
                    <CustomInput
                      label={"Address"}
                      type={"text"}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      defaultValue={userInfo.address}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"address"}
                      placeholder={"Street / Road name"}
                      onChange={(value) => HandleInput("address", value)}
                    />
                  </td>
                  <td>
                    <CustomInput
                      label={"City"}
                      type={"text"}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      defaultValue={userInfo.city}
                      inputClassName={"rounded"}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"city"}
                      placeholder={"City"}
                      onChange={(value) => HandleInput("city", value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomInput
                      label={"State"}
                      type={"text"}
                      defaultValue={userInfo.state}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"state"}
                      placeholder={"State"}
                      onChange={(value) => HandleInput("state", value)}
                    />
                  </td>
                  <td>
                    <CustomInput
                      label={"Pincode"}
                      maxLength={8}
                      type={"text"}
                      defaultValue={userInfo.pincode}
                      className={"border border-gray-500 h-[5vh] rounded"}
                      inputClassName={"rounded"}
                      outerDivClass={
                        "flex justify-between items-center space-x-3"
                      }
                      labelClass={"text-lg font-bold text-black"}
                      required={true}
                      name={"pincode"}
                      placeholder={"Pincode"}
                      onChange={(value) => HandleInput("pincode", value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomCheckbox
                      checkClass={"h-5 w-5 border border-black"}
                      options={[
                        {
                          label: "I have a pet shop",
                          value: 1,
                        },
                      ]}
                      defaultValue={[userInfo.hadshop]}
                      onChange={(value) => {
                        HandleInput("hadshop", value.includes(1) ? 1 : 0);
                      }}
                      SelectlabelClass={
                        "text-lg font-bold text-black text-gray-600"
                      }
                    />
                  </td>
                </tr>
                {userInfo.hadshop ? (
                  <>
                    <tr>
                      <th colspan="3" className="text-left">
                        <h1 className="text-secondary font-bold text-2xl">
                          Shop Information
                        </h1>
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <CustomCheckbox
                          checkClass={"h-5 w-5 border border-black"}
                          options={[
                            {
                              label: "Copy from personal address",
                              value: 1,
                            },
                          ]}
                          defaultValue={[userInfo.sameaspersonaladdress]}
                          onChange={(value) => {
                            personalAddressandShopAddresssame(
                              value.includes(1) ? 1 : 0
                            );
                          }}
                          SelectlabelClass={
                            "text-lg font-bold text-black text-gray-600"
                          }
                        />
                      </td>
                    </tr>

                    {!userInfo.sameaspersonaladdress ? (
                      <>
                        <tr>
                          <td>
                            <CustomInput
                              label={"Shop name"}
                              type={"text"}
                              className={
                                "border border-gray-500 h-[5vh] rounded"
                              }
                              inputClassName={"rounded"}
                              outerDivClass={
                                "flex justify-between items-center space-x-3"
                              }
                              defaultValue={userInfo.shopname}
                              labelClass={"text-lg font-bold text-black"}
                              required={true}
                              onChange={(value) =>
                                HandleInput("shopname", value)
                              }
                              name={"shopname"}
                              placeholder={"Shop name"}
                            />
                          </td>
                          <td>
                            <CustomInput
                              label={"Address"}
                              type={"text"}
                              className={
                                "border border-gray-500 h-[5vh] rounded"
                              }
                              inputClassName={"rounded"}
                              outerDivClass={
                                "flex justify-between items-center space-x-3"
                              }
                              labelClass={"text-lg font-bold text-black"}
                              required={true}
                              value={userInfo.shopaddress}
                              name={"shopaddress"}
                              placeholder={"Street / Road name"}
                              onChange={(value) =>
                                HandleInput("shopaddress", value)
                              }
                            />
                          </td>
                          <td>
                            <CustomInput
                              label={"City"}
                              type={"text"}
                              className={
                                "border border-gray-500 h-[5vh] rounded"
                              }
                              inputClassName={"rounded"}
                              outerDivClass={
                                "flex justify-between items-center space-x-3"
                              }
                              defaultValue={userInfo.shopcity}
                              labelClass={"text-lg font-bold text-black"}
                              required={true}
                              name={"shopcity"}
                              placeholder={"Shop city"}
                              onChange={(value) =>
                                HandleInput("shopcity", value)
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <CustomInput
                              label={"State"}
                              type={"text"}
                              className={
                                "border border-gray-500 h-[5vh] rounded"
                              }
                              inputClassName={"rounded"}
                              outerDivClass={
                                "flex justify-between items-center space-x-3"
                              }
                              defaultValue={userInfo.shopstate}
                              labelClass={"text-lg font-bold text-black"}
                              required={true}
                              name={"shopstate"}
                              placeholder={"Shop state"}
                              onChange={(value) =>
                                HandleInput("shopstate", value)
                              }
                            />
                          </td>
                          <td>
                            <CustomInput
                              label={"Pincode"}
                              maxLength={8}
                              type={"text"}
                              className={
                                "border border-gray-500 h-[5vh] rounded"
                              }
                              inputClassName={"rounded"}
                              outerDivClass={
                                "flex justify-between items-center space-x-3"
                              }
                              defaultValue={userInfo.shoppincode}
                              labelClass={"text-lg font-bold text-black"}
                              required={true}
                              name={"shoppincode"}
                              placeholder={"Shop pincode"}
                              onChange={(value) =>
                                HandleInput("shoppincode", value)
                              }
                            />
                          </td>
                          <td>
                            <CustomInput
                              label={"Contact"}
                              maxLength={8}
                              type={"text"}
                              className={
                                "border border-gray-500 h-[5vh] rounded"
                              }
                              inputClassName={"rounded"}
                              outerDivClass={
                                "flex justify-between items-center space-x-3"
                              }
                              defaultValue={userInfo.shopcontact}
                              labelClass={"text-lg font-bold text-black"}
                              required={true}
                              name={"shopcontact"}
                              placeholder={"Contact Information"}
                            />
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td>
                          <CustomInput
                            label={"Shop name"}
                            type={"text"}
                            className={"border border-gray-500 h-[5vh] rounded"}
                            inputClassName={"rounded"}
                            outerDivClass={
                              "flex justify-between items-center space-x-3"
                            }
                            defaultValue={userInfo.shopname}
                            labelClass={"text-lg font-bold text-black"}
                            required={true}
                            onChange={(value) => HandleInput("shopname", value)}
                            name={"shopname"}
                            placeholder={"Shop name"}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <button className="bg-transparent border-2 border-secondary text-secondary m-3 font-bold px-7 uppercase py-2  rounded-lg">
              sign up <FontAwesomeIcon icon={faArrowRight} />
            </button>
            <br />
            <p>or</p>
            <div className="flex justify-center mt-4 space-x-5">
              <img src={googleIcon} className="h-7" alt="google-icon" />
              <img src={callIcon} className="h-7" alt="google-icon" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupNewUser;
