import React from "react";
import { Link } from "react-router-dom";
import CustomButton from "./customBtn";

function Header({ navigate }) {
  return (
    <header className="flex flex-row items-center w-full justify-between px-4 border-bottom-1">
      <div className="logo px-6">
        <p className="text-white text-xl">Logo</p>
      </div>
      <div className="flex flex-row justify-center space-x-9 text-xl">
        <Link className="text-white font-bold">Pet type</Link>
        <Link className="text-white font-bold">Pet services</Link>
        <Link className="text-white font-bold">Pet breeds</Link>
        <Link className="text-white font-bold">Pet foods</Link>
        <Link className="text-white font-bold">About your pets</Link>
        <Link className="text-white font-bold">Get connect with us</Link>
        <Link className="text-white font-bold">About us</Link>
      </div>
      <CustomButton
        className="border bg-white px-5 py-3 border-2 rounded-lg text-dark font-bold text-xl"
        label={" Register / Login"}
        onClick={() => navigate("/login")}
      />
    </header>
  );
}

export default Header;
