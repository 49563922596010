import {
  faHeart,
  faLocationDot,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const PetCardsDesktop = ({
  imgUrl,
  petName,
  petType,
  petBreed,
  petLocation,
  petGender,
  petYob,
  wishList,
  showInfo,
  HandleWishList,
}) => {
  return (
    <div className="card-desktop cursor-pointer relative shadow-lg m-4 border border-gray-200 bg-gray-200 max-w-[20vw] p-3">
      {/* ribbon */}
      {/* <div className="absolute flex flex-row items-center space-x-2 bg-black  top-0 left-0 text-center px-3">
        <FontAwesomeIcon icon={faCheckCircle} color="#" />
        <p className="text-light font-bold text-[15px]">Verified</p>
      </div> */}
      {/* favourites */}
      <div
        className="absolute right-0 cursor-pointer top-0"
        onClick={() => HandleWishList()}
      >
        <FontAwesomeIcon
          icon={faHeart}
          style={{
            fontSize: 18,
            backgroundColor: "white",
            color: wishList ? "#FF0000" : "gray",
            padding: 10,
            borderRadius: "50%",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className="card-desktop-img border border-gray-200 h-[25vh] bg-no-repeat bg-center bg-contain"
        onClick={() => showInfo()}
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      ></div>
      <div className="card-body py-3">
        <p onClick={() => showInfo()} className="text-center text-xl font-bold">
          {petName}
        </p>
        <p
          onClick={() => showInfo()}
          className="text-md text-center font-bold text-gray-600 px-2"
        >
          {petType} | {new Date().getFullYear() - parseInt(petYob)}yrs |{" "}
          {petBreed}
        </p>
        <div
          onClick={() => showInfo()}
          className="flex flex-row justify-evenly py-4 items-center text-dark"
        >
          <div className="flex flex-row text-sm font-bold items-center space-x-4">
            <FontAwesomeIcon icon={faLocationDot} />
            <p className="text-dark">{petLocation}</p>
          </div>
          <div className="flex flex-row text-sm font-bold items-center space-x-4">
            <FontAwesomeIcon icon={faVenusMars} />
            <p className="text-dark">{petGender}</p>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-5 justify-center ">
          <button className="border border-2 text-sm border-green-500 rounded py-1 px-3">
            Send Message
          </button>
          <button className="border border-2 text-sm border-blue-500 rounded py-1 px-3">
            Make a call
          </button>
        </div>
      </div>
    </div>
  );
};

export const PetCardsMobile = () => {};
