import { faArrowRight, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const AddPetCompletedPage = () => {
  return (
    <div className="flex flex-col space-y-2 justify-center text-center items-center fixed top-0 left-0 right-0 bottom-0">
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="text-secondary text-9xl"
      />
      <h2 className="text-black font-bold text-2xl">
        New pet added successfully
      </h2>
      <h3>Your pet will be shown once the verification process is completed</h3>
      <Link
        to={"/member/dashboard/managepets"}
        className="text-blue-600 font-bold cursor-pointer"
      >
        GO DASHBOARD <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
      </Link>
    </div>
  );
};

export default AddPetCompletedPage;
