import React from "react";
import { PetCardsDesktop } from "../../../components/petCards";
import CustomMultiSelectDropdown from "../../../components/customMultiSelect";

function PetListViewDesktop() {
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
      <PetCardsDesktop />
    </div>
  );
}

export default PetListViewDesktop;
