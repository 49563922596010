import React, { useContext } from "react";
import PreloaderContext from "../context/preloaderContext";
const preloader = require("../assets/images/gif/loader.gif");
function Preloader() {
  const { showPreloader } = useContext(PreloaderContext);
  if (showPreloader)
    return (
      <div className="bg-white fixed top-0 bottom-0 left-0 right-0 z-5 flex flex-col justify-center items-center bg-opacity-70">
        <img src={preloader} alt="" className="h-[14vh]" />
        <p className="text-2xl text-black mt-3 font-bold">Please wait...</p>
      </div>
    );
  else return <></>;
}

export default Preloader;
