import React, { useState } from "react";
import PetListFiltersDesktop from "./listFilters";
import PetListViewDesktop from "./petListView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
function AboptPetListWithFiltersDesktop() {
  const [searchValue, setSearchValue] = useState("");
  return (
    <>
      <div className="flex flex-row justify-center p-3">
        <div className="bg-light relative border border-gray-400">
          <input
            placeholder={"Search for pet"}
            type={"text"}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            className={"p-3 w-[20vw] font-bold text-md"}
          />
          <p className="absolute right-0 top-3 px-3 text-gray-800">
            {(searchValue === "" && (
              <FontAwesomeIcon icon={faSearch} className="h-5" />
            )) || (
              <FontAwesomeIcon
                icon={faXmarkCircle}
                className="h-5"
                onClick={() => setSearchValue("")}
              />
            )}
          </p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="bg-secondary w-1/4 h-[95vh] bg-cover bg-center fixed left-0">
          <PetListFiltersDesktop />
        </div>
        <div className="bg-ternary w-3/4 h-[95vh] bg-cover fixed right-0 overflow-y-auto bg-center">
          <PetListViewDesktop />
        </div>
      </div>
    </>
  );
}

export default AboptPetListWithFiltersDesktop;
