import React, { useContext } from "react";
import CustomButton from "../../components/customBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeartCircleCheck,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";
import PreloaderContext from "../../context/preloaderContext";
const bgImg = require("../../assets/images/png/pet-bg-img.png");
function DesktopWelcome() {
  const navigate = useNavigate();
  const { setShowPreloader } = useContext(PreloaderContext);
  const dogFromTearPaper = require("../../assets/images/png/dog-from-tear-sheet.png");
  return (
    <>
      <div className="flex flex-col bg-secondary space-y-6 justify-center items-center fixed top-0 bottom-0 left-0 right-0">
        <div className="absolute top-5 right-0 left-0">
          <Header navigate={navigate} />
        </div>

        {/* background images */}
        <div
          className="fixed top-0 bottom-0 left-0 right-0 z-[-1] "
          style={{
            // backgroundImage: `url(${bgImg})`,
            backgroundSize: "20vw",
            opacity: "30%",
          }}
        ></div>
        <div className="flex-row space-x-9">
          <CustomButton
            className={
              "bg-dark shadow-2xl hover:shadow-md text-light hover:border-2 border-dark hover:text-dark hover:bg-transparent rounded-[55%] min-w-[12vw] min-h-[23vh] text-lg"
            }
            label={
              <div>
                <FontAwesomeIcon icon={faPlus} size="2xl" className="my-2" />
                <p className="font-bold">Add pet</p>
              </div>
            }
            onClick={() => navigate("/add-pet/1")}
          />
          <CustomButton
            className={
              "bg-dark shadow-2xl hover:shadow-md text-light hover:border-2 border-dark hover:text-dark hover:bg-transparent rounded-[55%] min-w-[12vw] min-h-[23vh] text-lg"
            }
            label={
              <div>
                <FontAwesomeIcon
                  icon={faHeartCircleCheck}
                  size="2xl"
                  className="my-2 text-light"
                />
                <p className="font-bold text-light">Buy/Adopt pet</p>
              </div>
            }
            onClick={() => navigate("/adopt-pet")}
          />
        </div>
        <CustomButton
          className={
            "bg-dark shadow-2xl hover:shadow-md text-light hover:border-2 border-dark hover:text-dark hover:bg-transparent rounded-[55%] min-w-[14vw] min-h-[26vh] text-lg"
          }
          label={
            <div>
              <FontAwesomeIcon
                icon={faSearch}
                size="2xl"
                className="my-2 text-light"
              />
              <p className="font-bold text-light">Find a pet partner</p>
            </div>
          }
          onClick={() => navigate("/find-parnter")}
        />
        <img
          src={dogFromTearPaper}
          alt=""
          className="z-[-1] fixed top-[50%] bottom-[50%] right-20 transform -translate-y-1/2 h-[28vh]"
        />
      </div>
    </>
  );
}

export default DesktopWelcome;
