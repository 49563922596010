import { useEffect, useState } from "react";

const CustomRadioBtn = ({
  options,
  labelClass,
  radioClass,
  defaultValue,
  onChange,
  name,
  outerDivClass,
  label,
  SelectlabelClass,
  required,
}) => {
  return (
    <div className={outerDivClass}>
      <label className={labelClass}>
        {label}{" "}
        {required && <span className="text-md text-red-600 font-bold">*</span>}
      </label>
      <div className="flex flex-row space-x-5">
        {options &&
          options.map((items, index) => (
            <div className="flex flex-row items-center space-x-3">
              <input
                type="radio"
                id={index}
                className={radioClass}
                name={name}
                onChange={() => onChange(items.value)}
                checked={items.value === defaultValue}
                required={required}
              />
              <label className={SelectlabelClass}>{items.label}</label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomRadioBtn;
